import React, { Component } from 'react';
import { connect } from "react-redux";
// @ts-ignore
import  { Redirect } from 'react-router-dom';
import { AiOutlineLeft, AiOutlineRight, AiFillCloseCircle, AiOutlineSearch } from "react-icons/ai";
import { SiAddthis } from "react-icons/si";
import { get, noop } from 'lodash';
import style from './Login.module.scss';
import globalStyle from './login-appStyles.module.scss';
import { getAuthToken } from "../../../utils/authService";
import { sendPhoneVerification, verifyOtp } from "../../../Actions/app.actions";
import {history} from '../History/index';
import CustomerCANorPhone from '../CustomerCANorPhone/CustomerCANorPhone'
// import {GaEvent} from "../../utils/tracking";
// import {eventActions} from "../../Constants/analytics";
import popupStyle from '../CustomerCANorPhone/CustomerCANorPhone.module.scss';


type Props = {
    sendPhoneVerification: (phone: string, successCallback: any, failureCallback: any) => any;
    verifyOtp: (phoneOtp: string, phoneNo: string, successCallback: any, failureCallback: any) => any;
    //getIBAccountStatus: (successCallback: any, failureCallback: any) => any;
    userRole: string;
    adminToken: string;
    kycPhone: string;
    tokenExpiry: boolean;
};
type State = {
    phoneNo: string;
    toggleOtp: boolean;
    phoneOtp: string;
    loggedIn: boolean;
    error: boolean;
    otpError:boolean;
    loading:boolean;
    
};

class Login extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            phoneNo: props.kycPhone || "",
            toggleOtp : false,
            phoneOtp: '',
            loggedIn: false,
            error:false,
            otpError:false,
            loading: false,
            
        }
    }

    componentDidMount() {

    }

    updateField = (field: string, value: string) => {
        // @ts-ignore
        this.setState({ [field]: value });
        // this.setActivityLog(field);
    }

    handleKeyDown = (e: any, disableSubmit: boolean) => {
        if (e.key.toLowerCase() === 'enter' && !disableSubmit) {
            this.onSubmit();
        }
    }

    onSubmit = () => {
        const { phoneNo } = this.state;
        const { sendPhoneVerification } = this.props;
        this.setState({loading : true})
        // GaEvent(eventActions.categories.CHATBOT, eventActions.actions.SEND_PHONE_VERIFICATION, `Send phone verification`);
        sendPhoneVerification(phoneNo, this.setPhoneOtpVerification, this.phoneVerificationFailed);
    }

    setPhoneOtpVerification = () => {
        const { toggleOtp } = this.state;
        this.setState({toggleOtp : true, error: false, otpError: false, loading : false });
    };

    phoneVerificationFailed = () => {
        const { toggleOtp } = this.state;
        this.setState({toggleOtp : false, error: true, loading : false});
    };

    onConfirmOtp = () => {
        const { phoneOtp, phoneNo} = this.state;
        const { verifyOtp } = this.props;
        this.setState({loading : true})
        //GaEvent(eventActions.categories.CHATBOT, eventActions.actions.VERIFY_OTP, `Verify OTP`);
        verifyOtp(phoneOtp, phoneNo,this.verificationSuccess, this.verificationFailed)
    }

    verificationSuccess = () => {
       // const { getIBAccountStatus } = this.props
        this.setState({loggedIn: true, otpError: false, loading : false})
        // console.log('FLAG-1')
        //getIBAccountStatus(this.onFetchSuccess, this.onFetchFail)
    };

    


    verificationFailed = () => {
        this.setState({otpError: true, loading : false});
    };

    handleClick(){
        window.location.pathname = "/";
    }

    render(){
        const { kycPhone, tokenExpiry, userRole, adminToken } = this.props;
        const { phoneNo, toggleOtp, phoneOtp, otpError, loading, error, loggedIn} = this.state;
        console.log("user Role: and admin Token" ,userRole, adminToken)
        return (
          <div>
            <section className={`${globalStyle.bodyContainer} ${style.landingImg}`}>
                <div className={style.loginContainer}>
                    <div className={globalStyle.flexSpaceAround}>
                        <div></div>
                        {!toggleOtp ?
                        <div className={`${style.phoneNoContainer} ${globalStyle.flexColumn} `}>
                            <div className={style.signupTitle}>Login</div>
                            <div className={`${globalStyle.flexVerticalCenter} ${style.phoneInputFieldWrapper}`}>
                                 <div className={globalStyle.flexVertical}>
                                    <div className={`${globalStyle.textFieldContainer} ${globalStyle.textFieldOutlineWrapper} ${style.phoneTextFieldOutline}`}>
                                        <span className={globalStyle.inputGroupAddon}>91+</span>
                                        <input
                                            type="number"
                                            value={phoneNo}
                                            className={`${globalStyle.textField} ${globalStyle.textFieldOutline} ${globalStyle.addOnTextField}`}
                                            onChange={(e: any) => this.updateField('phoneNo', e.target.value)}
                                            placeholder="Enter your mobile number"
                                            onKeyPress={(e: any) => this.handleKeyDown(e, false)}
                                            disabled={false}
                                        />
                                    </div>
                                    {error &&
                                    <div className={style.errorMsg}>
                                        We couldn't send OTP to the provided number, please review your phone number.
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className={style.otpMsg}>You will receive an OTP on your number</div>
                            <div className={`${globalStyle.flexVerticalCenter} ${style.loginBtnWrapper}`}>
                                <button
                                        className={`${globalStyle.submitButton} ${style.loginBtn}  ${false ? globalStyle.disabledField : ''}`}
                                        onClick={this.onSubmit}
                                        disabled={false}
                                    >
                                        Continue {loading && <img src="/static/images/loader-white.gif" alt="chat-msg-spinner" height={25}/> }
                                </button>
                            </div>
                        </div>
                        :
                        <div className={`${style.otpContainer} ${globalStyle.flexColumn} `}>
                            <div className={style.signupTitle}>Confirm OTP</div>
                            <div className={style.subTitle}>Enter OTP sent to <span>+91 {kycPhone}</span></div>
                            <div className={`${globalStyle.flexVerticalCenter} ${style.phoneInputFieldWrapper}`}>
                                <div className={globalStyle.flexVertical}>
                                    <div className={style.textFieldWrapper}>
                                        <input
                                            type="number"
                                            className={`${globalStyle.textField} ${style.otpField}`}
                                            value={phoneOtp}
                                            onChange={(e: any) => this.setState({ phoneOtp: e.target.value })}
                                            placeholder="Enter the OTP"
                                        />
                                    </div>
                                    {otpError &&
                                    <div className={style.errorMsg}>
                                        OTP does not match, please validate your phone number or OTP.
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className={`${style.otpMsg} ${style.cursorPointer}`} onClick={() => this.setState({ toggleOtp: false })} >Didn't receive OTP?</div>
                            <div className={`${globalStyle.flexVerticalCenter} ${style.loginBtnWrapper}`}>
                                <button
                                        className={`${globalStyle.submitButton} ${style.loginBtn}  ${false ? globalStyle.disabledField : ''}`}
                                        onClick={this.onConfirmOtp}
                                        disabled={false}
                                    >
                                        Continue {loading && <img src="/static/images/loader-white.gif" alt="chat-msg-spinner" height={25}/> }
                                </button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
            {/* <CustomerCANorPhone/> */}
            { userRole !=='' && (userRole === 'ADMIN' ? 
            <CustomerCANorPhone adminToken={adminToken}/>
            : 
            <div className={popupStyle.popup}>
                <div className={popupStyle.popupInner}>
                    <button className={popupStyle.closeBtn} onClick={this.handleClick}>
                        
                        close
                        
                    </button>
                    <label>Access Denied</label>
                </div>
            </div>
            )}
        </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    kycPhone: state.appStore.kycPhone,
    tokenExpiry: state.appStore.tokenExpiry,
    userRole: state.appStore.userRole,
    adminToken: state.appStore.adminToken,
});

const mapDispatchToProps = (dispatch: any) => ({
    sendPhoneVerification: (phone: string, successCallback: any, failureCallback: any) => dispatch(sendPhoneVerification(phone, successCallback, failureCallback)),
    verifyOtp: (phoneOtp: string, phoneNo: string,successCallback: any, failureCallback: any) => dispatch(verifyOtp(phoneOtp, phoneNo,successCallback, failureCallback)),
    
    // getIBAccountStatus: (successCallback: any, failureCallback: any) => dispatch(getIBAccountStatus(successCallback, failureCallback)),
    // setSelectedFunds: (selectedFunds: any) => dispatch(setSelectedFunds(selectedFunds)),
    // expandSidebar: (flag: boolean) => dispatch(expandSidebar(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
