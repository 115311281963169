import React, { Component } from 'react';
import './KYCDownload.scss';
import Smallcase from '../Smallcase/Smallcase';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import style from '../KYCFetch/KYCIndividualForm.module.scss';
import { IdentityDetails, AddressDetails, Instructions, DelcarationSection, OfficeUseSection } from '../KYCFetch/KYCIndividual/KYCIndividualFormUtil/KYCIndividualFormUtil';

class KYCDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycformData: [],
      load: true,
      PAN: "",
      DOB: ""
    }
  }

  handlePAN = (e) => {
    const panValue = e.target.value.toUpperCase(); // Convert to uppercase
    this.setState({ PAN: panValue });

    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (panValue.length === 10 && !panPattern.test(panValue)) {
      console.log("Invalid PAN format");
    }
  };

  handleDOB = (e) => {
    const dobValue = e.target.value;
    const formattedDOB = dobValue.replace(/[^0-9/]/g, ''); // Keep only numbers and "/"
    this.setState({ DOB: formattedDOB });

    const dobPattern = /^\d{2}\/\d{2}\/\d{4}$/;

    if (formattedDOB.length === 10 && !dobPattern.test(formattedDOB)) {
      console.log("Invalid DOB format");
    }
  };

  isFormValid = () => {
    const { PAN, DOB } = this.state;
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const dobPattern = /^\d{2}\/\d{2}\/\d{4}$/;

    return panPattern.test(PAN) && dobPattern.test(DOB);
  }

  handleDownload = (event) => {
    const { PAN, DOB } = this.state;

    if (this.isFormValid()) {
      this.fetchData({ PAN: PAN, DOB: DOB });
    } else {
      alert("Please enter correct PAN and DOB.");
    }
  }

  fetchData(data) {
    console.log(data, "data")
    const value = [data.PAN, data.DOB];

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ value: value })
    };
    fetch('https://api.lotusdew.in/kyc/fetches_test', requestOptions)
      .then(response => response.json())
      .then(res => {
        if (res.success === true) {
          this.setState({ kycformData: res.insert_data });
          this.setState({ load: false });
          let id = "individualkycform";
          let HTML_Width = 800;
          let HTML_Height = document.getElementById(id).offsetHeight;
          let top_left_margin = 15;
          let PDF_Width = HTML_Width + (top_left_margin * 2);
          let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
          let canvas_image_width = HTML_Width;
          let canvas_image_height = HTML_Height;

          let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

          window.scrollTo(0, 0);
          html2canvas(document.getElementById(id), { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height + 10]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

            for (let i = 1; i <= totalPDFPages; i++) {
              pdf.addPage(PDF_Width, PDF_Height);
              pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin), canvas_image_width, canvas_image_height);
            }
            pdf.save(value[0] + ".pdf");
          });
          alert(`Download successful, saved file as ${data.PAN}.pdf`);
          this.setState({ PAN: "", DOB: "" });
        } else {
          alert(`Failed to download user KYC with PAN ${data.PAN}`);
        }
      })
      .catch(error => {
        alert(`Failed to download user KYC with PAN ${data.PAN}`);
      });
  }

  render() {
    const { kycformData, load, PAN, DOB } = this.state;

    return (
      <div>
        <Smallcase />
        <div className={style.cont}>
          <div className="form-container">
            <h2 className="form-heading">Download KYC Form</h2>
            <input
              className={`input-field ${PAN.length === 10 && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(PAN) ? 'invalid' : ''}`}
              type="text"
              value={PAN}
              onChange={this.handlePAN}
              placeholder="Enter PAN"
              maxLength="10"
              required
            />
            <input
              className={`input-field ${DOB.length === 10 && !/^\d{2}\/\d{2}\/\d{4}$/.test(DOB) ? 'invalid' : ''}`}
              type="text"
              value={DOB}
              onChange={this.handleDOB}
              placeholder="DD/MM/YYYY"
              maxLength="10"
              required
            />
            <button className="download-btn" onClick={this.handleDownload} disabled={!this.isFormValid()}>
              Download KYC
            </button>
          </div>

          {load ? '' : (
            <div className={`${style.kycHide}`}>
              <div id="individualkycform" className={`${style.kycForm}`}>
                <div className={`${style.formBody} m-4`}>
                  <div className={`${style.headerSecGap}`}></div>
                  <div className="pt-3">
                    <div className="float-left ml-5 mr-2">
                      <span>Know Your Client(KYC) <br />
                        Application Form (For Individuals Only)</span>
                      <hr className={`${style.headerHrLine}`} />
                      <span>Please Fill in English and in BLOCK LETTERS</span>
                    </div>
                    <div className="float-left mr-2">
                      <img src="/static/images/KycindividualLogo.JPG"
                        className={`${style.fixedH67}`} alt='' />
                    </div>
                    <div className="float-left pt-2">
                      <div className={`${style.internmediaryLogo} internmediary-logo mr-3 float-left`}>
                        Place for intermediary Logo
                      </div>
                      <span>Application No.: {kycformData.application_kyc_data.application_no}</span>
                    </div>
                  </div>

                  <IdentityDetails kycformData={kycformData}></IdentityDetails>
                  <AddressDetails kycformData={kycformData}></AddressDetails>
                  <DelcarationSection kycformData={kycformData}></DelcarationSection>
                  <OfficeUseSection kycformData={kycformData}></OfficeUseSection>
                  <Instructions></Instructions>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default KYCDownload;
