import React, { Component } from 'react'
//import { decrypt } from '../../utils/encrypt';
import { get } from 'lodash';
import style from './CustomerCANorPhone.module.scss'
import  { Redirect } from 'react-router-dom';
import PortfolioDownload from '../PortfolioDownload';
import SendNewsLetterModal from '../../Smallcase/SendNewsLetter/SendNewsLetterModal'
import SendNewsLetterModalContent from '../../Smallcase/SendNewsLetter/SendNesLetterModalContent';
import { modalStyle, mainStyle, colourStyles } from "../../city/utils/style";

export default class CustomerCANorPhone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            option: 'can',
            userNo : '',
            isModalOpen:false,
            responseData: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.updateField = this.updateField.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.decrypt = this.decrypt.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
      }

      openModal = () => {
        this.setState({
            isModalOpen: true
        });
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false,
            responseData:'',
            
        });
    }
    
      handleChange(event) {
        //   console.log(event.target.value);    
        //   this.setState({option: event.target.value});  
        }

        updateField = (field, value) => {
            // @ts-ignore
            // console.log(value);
            this.setState({ [field]: value });
            this.setState({
                userNo:value
            })
            // this.setActivityLog(field);
        }

        handleKeyDown = (e, disableSubmit) => {
            if (e.key.toLowerCase() === 'enter' && !disableSubmit) {
                this.onSubmit();
            }
        }

        
    decrypt = (data) => {
        
        const algorithm = 'aes-256-cbc';
        const key = 'kYp3s6v9y$B&E)H+MbQeThWmZq4t7w!z';
        const iv = 'hWmZq3t6w9z$C&F)';
        const crypto = require('crypto');

        const decipher = crypto.createDecipheriv(algorithm, key, iv);
        let decrypted = decipher.update(data, 'base64', 'binary');
        decrypted = decrypted + decipher.final('binary');
        return decrypted;
    }


    generateFile = (content, fileName) =>{
       
        const linkSource = `data:application/pdf;base64,${content}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    
        handleSubmit(event) {
            event.preventDefault();
           
            const opt = this.state.option;
            const No = this.state.userNo;
            //console.log("this is the admin token",this.props.adminToken)
            const requestOptions = {
                method: 'GET',
                headers: {'Authorization': `Bearer ${this.props.adminToken}` },
            };
                      
            if(opt!=='' && No !==''){
            fetch(`https://gateway.lotusdew.in/mfutils/download_portfolio?${opt}=${No}`,requestOptions)
            .then(response => response.json())
             .then(data => {
                //  console.log(data,"data")
                 if(data.encryptedData !== undefined)
                 {
                    var res = this.decrypt(get(data,'encryptedData',''));
                //  console.log(res,"res")
                 const decdata = JSON.parse(res);
                //  console.log(decdata,"decdata");
                //  console.log(decdata);
                 if(decdata.success=== true){
                     const fileName = `${No}.pdf`;
                     this.generateFile(decdata.b64, fileName);
                     this.setState({responseData : decdata.message})
                     this.openModal();
                 }
                }
                 else if(data.success === false){
                     this.setState({responseData : data.message})
                     this.openModal();
                 }   
             })
        }
            else{
                this.setState({responseData : "Please Enter the Customer detail!"})
                     this.openModal();
            }
            
          }

        handleClick(){
            window.location.pathname = "/download_portfolio";
        }
    
      render() {
          const {phoneNo, option,responseData}= this.state

        return (
          <div>
    
            <SendNewsLetterModal
                    isModalOpen={this.state.isModalOpen}
                    closeModal={this.closeModal}
                    style={modalStyle}
                >
                    <SendNewsLetterModalContent
                        responseData={responseData}
                        mainStyle={mainStyle}
                        closeModal={this.closeModal}
                    />
                </SendNewsLetterModal>
              
            
          
          <div className={style.popup}>
          <div className={style.popupInner}>
          <button className={style.closeBtn} onClick={this.handleClick}>close</button>
            <div className={style.Container}>
                <label> Enter CAN or Phone Number of Customer</label>
                <div className={style.inputSubCon}>
                    <select value={option} onChange={this.handleChange} className={style.choice}>
                        <option value="can">CAN</option>
                        <option value="phone">Phone No</option>
                    </select>
                    <input
                        type="text"
                        placeholder="Enter Customer Detail"
                        className={style.userinput}
                        value={phoneNo}
                        onChange={(e) => this.updateField('phoneNo', e.target.value)}
                        onKeyPress={(e) => this.handleKeyDown(e, false)}
                        disabled={false}
                    />
                </div>
                </div>
                <button className= {style.submit} onClick={this.handleSubmit}>Submit</button>
            </div>
          </div> 
          </div>
        );
      }
    }