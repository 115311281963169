import React, { Component } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import style from'./SendNewsLetter.module.scss';



class SendEmailEditorContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }


    render() {
        const {  toggleList, onToggle, onChangeRawHtMl, rawHtml, emailText, onChange, buttonHtml } = this.props;
        return (
            <>
             <div className={style.dFlex}>
                    <p className={style.status}>{toggleList ? "Raw HTML" :  "Email Editor" }</p>
                    <label className={style.switch}>
                      <input
                        type="checkbox"
                        checked={toggleList}
                        className={style.toggleList}
                        onChange={onToggle}
                      />
                      <span className={`${style.slider} ${style.round}`}></span>
                    </label>
                  </div>
                  {toggleList ? 
                   <textarea className={style.textArea} value={rawHtml} onChange={(e)=>onChangeRawHtMl(e)}placeholder="Raw HTML" rows="20" name="comment[text]" id="comment_text" cols="40" autoComplete="off"  aria-autocomplete="list" aria-haspopup="true"></textarea>
                  :
                  <Editor
                  //  initialValue={emailText && `<p>${emailText}</p>`}
                  value={emailText}
                   init={{
                     height: 500,
                    //  width: '55vw',
                     statusbar: false,
                     plugins: [
                      'link image code', 'textcolor', 'lists',
                     ],
                     placeholder: "Email Editor",
                     toolbar: 'undo redo | bold italic | numlist bullist | alignleft aligncenter alignright | code | currentdate | forecolor backcolor | Info ',
                     content_css: 'www.tiny.cloud/css/codepen.min.css',
                     setup: function(editor) { 
                              function insertBtn() {
                                var html = buttonHtml;
                                editor.insertContent(html);
                              }

                              function insertTag() {
                                var html = '';
                                editor.insertContent(html);
                              }
                              // editor.on('LoadContent', function(e) {
                              //     console.log('LoadContent event', e);
                              //     editor.insertContent(emailText);
                              //   });                  
                              editor.ui.registry.addButton('currentdate', {
                                text: "Insert Button",
                                tooltip: "Insert Button",
                                onAction: () => insertBtn()
                              });
                               editor.ui.registry.addButton('Info', {
                                   icon:'help',
                                   tooltip: "tags 1.Name {{name}} 2.unsubscribe link: {{unsubscribe}} 3. Firm Name: {{member_name}}",
                                   onAction: () => insertTag()
                               });
                            }
                   }}
                  onEditorChange={onChange}
                  id='uuid'
                  />
                  }
            </>
        )
    }
}

export default SendEmailEditorContent;