
import {
    SEND_PHONE_VERIFICATION_REQUEST,
    SEND_PHONE_VERIFICATION_SUCCESS,
    SEND_PHONE_VERIFICATION_FAILED,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    TOKEN_EXPIRY,
    USERLIST_REQUEST,
    USERLIST_SUCCESS,
    USERLIST_FAILED,
    SEND_TRADE_EMAIL_REQUEST,
    SEND_TRADE_EMAIL_SUCCESS,
    SEND_TRADE_EMAIL_FAILED,
    // SEND_NEWS_LETTER_REQUEST,
    // SEND_NEWS_LETTER_SUCCESS,
    // SEND_NEWS_LETTER_FAILED,
    

} from '../Constants/actionTypes.constants';
import { apiRequest } from "./apiCalls";
import config from '../config';

export const sendPhoneVerification = (phone: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    console.log("base url:",config.baseUrl)
    dispatch({ type: SEND_PHONE_VERIFICATION_REQUEST, data: { phone } });
    return apiRequest().post(`https://api.lotusdew.in/api/send_phone_verification`, { phone })
        .then((res) => {
            dispatch({ type: SEND_PHONE_VERIFICATION_SUCCESS, data: res.data, phone: phone });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: SEND_PHONE_VERIFICATION_FAILED, error });
            failureCallback();
        });
};

export const verifyOtp = (phoneOtp: string, phoneNumber: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: VERIFY_OTP_REQUEST, data: { phoneOtp } });
    return apiRequest().post(`https://api.lotusdew.in/api/verify_otps`, { phoneOtp, phoneNumber })
        .then((res) => {
            console.log("verify otp:",res);
            dispatch({ type: VERIFY_OTP_SUCCESS, data: res.data, phoneOtp: phoneOtp });
            successCallback();
        })
        .catch((error) => {
            dispatch({ type: VERIFY_OTP_FAILED, error });
            failureCallback();
        });
};


export const tokenExpiry = () => (dispatch: any) => {
    dispatch({ type: TOKEN_EXPIRY });
};

// export const sendnewsletter  = (details: any, successCallback: any, failureCallback: any) => (dispatch: any) => {
//     dispatch({ type: SEND_NEWS_LETTER_REQUEST, details });
//     return apiRequest().post(`https://chat-crm.lotusdew.in/smallcase/send_newsletter`, details,{formData:true})
//       .then((res) => {
//         dispatch({ type: SEND_NEWS_LETTER_SUCCESS, data: res });
//         successCallback();
//       })
//       .catch((error) => {
//         dispatch({ type: SEND_NEWS_LETTER_FAILED, error });
//         failureCallback();
//       });
//   };

export const listOfUser = (successCallback: any, failureCallback: any) => (dispatch: any) => {
    dispatch({ type: USERLIST_REQUEST });
    return apiRequest()
      .get('https://gateway.lotusdew.in/oauth/registered_users')
      .then(res => {
        //   console.log("res:",res.data)
        dispatch({ type: USERLIST_SUCCESS, data: res.data });
        successCallback();
      })
      .catch(error => {
        dispatch({ type: USERLIST_FAILED, error });
        failureCallback();
      });
  };


  export const sendTradeEmail = (b64: any,subject: string,to: string,from: any,body: string,
    filename: string, successCallback: any, failureCallback: any) => (dispatch: any) => {
        dispatch({ type: SEND_TRADE_EMAIL_REQUEST, data: { b64, subject, to, from, body, filename } });
    return apiRequest().post(`https://gateway.lotusdew.in/oauth/send_trade_email`, { b64, subject, to, from, body, filename })
      .then((res) => {
          // console.log("response of sendTradeEmail",res)
        dispatch({ type: SEND_TRADE_EMAIL_SUCCESS, data: res.data });
        successCallback();
      })
      .catch((error) => {
        dispatch({ type: SEND_TRADE_EMAIL_FAILED, error });
        failureCallback();
      });
  };
  


