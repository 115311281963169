import React, { Component } from 'react';
import Select from 'react-select';
import style from './SendEmail.module.scss';
import { listOfUser } from '../../Actions/app.actions';
import { connect } from 'react-redux';


class EmailInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectOptions:[],
        }


    }

componentDidMount(){
    const { listOfUser } =  this.props;
    listOfUser(this.listOfUserSuccess,this.listOfUserFailed);
}


listOfUserSuccess() {
// console.log("sucess")

}
listOfUserFailed() {
    // console.log("failed")


}

    render() {
        const { handleChange, colourStyles, handleTextChange, onSave,
            condition, toEmail, fromName,MultiSelectHandleChange } = this.props;
            let finalUserList = [];
            Object.keys(this.props.registeredUsers).length !== 0 ?
            this.props.registeredUsers.map((d)=>{
                finalUserList.push({"value": d[1], "label": d[0]})
            })  : finalUserList=[]
               
        const customStyles = {

            container: (provided, state) => ({
                ...provided,
                outline: `none`,
            }),

            control: (provided, state) => ({
                ...provided,
                // background: '#fff',
                // border: '2px solid #5e5e5e',
                minHeight: '100%',
                height: '40px',
                marginTop: '15px',
                width: "80%",
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: `3px`,
                background: '#E8EEE',
                color: 'fff',
                fontSize: '.8em',
                border: `1px solid #98a4ae`,
                // boxShadow: state.isFocused ? null : null,
                // boxShadow: null,
                outline: 'none',
                // border: "none",
                boxShadow: "none",


            }),
            placeholder: (base) => ({
                ...base,
                fontSize: '1em',
                color: '#98a4ae',
                textAlign: 'center',
                fontWeight: 400,
                outline: `none`,
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: `20px`,
                padding: '0 6px',
                color: '#fff',
                textAlign: 'center',
                fontSize: '13px',
                outline: `none`,
                // border: `1px solid #98a4ae`

            }),

            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                color: '#fff',
                textAlign: 'center',
                outline: `none`,
              

            }),
            indicatorSeparator: (state) => ({
                display: 'none',
                outline: `none`,
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '100%',
                color: '#fff',
                outline: `none`,
            }),
            menu: (provided, state) => ({
                ...provided,
                fontSize: '.7em',
                outline: `none`,
                border: "none",
                boxShadow: "none",
                width: "80%",
                // hover: 'blue'
            }),
            option: (provided, state) => ({
                ...provided,
                // width: "80%",
                backgroundColor: '#32B260',
                color: '#fff',
                fontWeight:'700',
                outline: 'none',
                border: `2px solid transparent`,  
                borderColor: `#fff`,              // backgroundColor:'grey',
                // color: !isDisabled ? 'black' : '#cccccc',

            }),
        };
        return (

            <div>
            <input className={style.textField} type="text" id="subject" name="subject"  placeholder="Subject" onChange={(e) => handleTextChange(e,'subject')}></input>
            <input className={style.textField} type="text" id="to-email" name="to-email" value={toEmail} placeholder="To (email)" onChange={(e) => handleTextChange(e,'to-email')}></input>
                {/* <input className={style.textField} type="text" id="from-name" name="from-name" value={fromName} placeholder="From (name)" onChange={(e) => handleTextChange(e,'from-name')}/>    */}
                    <div>
                    <label className={style.lbl}>From</label>
                    <Select
                        isMulti
                        options={finalUserList}
                        styles={customStyles}
                        onChange={(e) => MultiSelectHandleChange(e)}
                    ></Select>
                </div>
                <button className={`${style.btn} ${style.save} `} disabled={!condition} onClick={onSave}>Send</button>
            </div>
        
    )
    }
}

const mapStateToProps = state => ({ 
    registeredUsers : state.appStore.registeredUsers,
    fromUsers:state.appStore.fromUsers,
})

const mapDispatchToProps = dispatch => ({
   listOfUser : ( successCallback, failureCallback) => dispatch(listOfUser(successCallback, failureCallback)),
  });

export default connect(mapStateToProps, mapDispatchToProps)(EmailInfo);
