import React, { Component } from 'react';
import Select from 'react-select';

import style from '../../city/City.module.scss';
// import Modal from "../Modal";

const colourStyles = {
    container: (base, state) => ({
        ...base,
      }),
      valueContainer: (base, state) => ({
        ...base,
      }),
      multiValue:(base, state) => ({
        ...base,
        backgroundColor: "#f4f7f8"
      }),
      indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: "#fff"
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: "#000"
      }),
      // option: (base, state) => ({
      //   ...base,
      //   color: "#f4f7f8 !important",
      //   backgroundColor: '#e8eeef !imporatnt'
      // }),
    menu: styles => ({ ...styles, backgroundColor: '#e8eeef' }),
    control: styles => ({ ...styles, backgroundColor: '#e8eeef' }),
     option: styles => ({ ...styles, color: '#f4f7f8 !imporatnt' }),
  };

class SendNewsLetterModalContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            Sentiment:['Highly Positive', 'Positive', 'Neutral', 'Negative', 'Extremely Negative'],
            selectedType:'',
        }
    }
    

    render() {
        const {  closeModal,  selectedType, savedData, scheduleList, callStatus, feedbackTypes, responseData,
           selectedSentimentType, handleChangeSentiment, mainStyle, comments, onComment, onsaveFeedback } = this.props;
        // const { Sentiment } = this.state;
        // const types =  Sentiment.map(list => {return {value: list, label: list}});
        return (
            <>
               <>
               <p>{responseData ? responseData : 'Something Went Wrong..'}</p>

               <button
                 style={{
                   ...mainStyle.button,
                   margin: 0,
                   width: "auto",
                   marginTop: 10,
                   backgroundColor:'#24a557',
                 }}
                 onClick={closeModal}
               >
                 Close
               </button>
               </>
                
              </>
        )
    }
}

export default SendNewsLetterModalContent;