import React, { Component } from 'react';
import Smallcase from '../Smallcase';
import style from  './UpdateCustList.module.scss'

class UpdateCustList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            dateTime: "",
            open: false,
            uploading: false,
            uploadmsg: ''

        }
        this.handleChange = this.handleChange.bind(this);
        this.OnFileSubmit = this.OnFileSubmit.bind(this);
        this.convertBase64 = this.convertBase64.bind(this);
    }


    componentDidMount() {

        fetch('https://chat-crm.lotusdew.in/smallcase/last_updated')
            .then(response => response.json())
            .then(data => {
                this.setState({
                    dateTime: data.last_updated
                })
            })
            .catch(error=>{
                alert(error + " last update date")
            })
        /*const formatDate = date => {
            return moment(date, 'MM/DD/YY').format('DD/MM/YYYY');
          };

          date = formatDate(date) 

          //console.log(datetime.last_updated) */

    }


    handleChange(e) {
        var fileInput =
            document.getElementById('file');

        var filePath = fileInput.value;

        // Allowing file type
        var allowedExtensions =
            /(\.csv)$/i;

        if (!allowedExtensions.exec(filePath)) {
            alert('Invalid file type, Should be CSV File!');
            fileInput.value = '';
            return false;
        }
        else {
            // console.log(e.target.files[0])
            const files = e.target.files;
            if (files && files[0]) this.setState({ file: files[0] });
        }

    };


    async OnFileSubmit(file) {
        if (file !== null) {
            const base64 = await this.convertBase64(file);
            // console.log("base64", base64)

            /* let formData = new FormData();
             //console.log("file",base64)
             formData.append('customer_list', base64);
            // formData.append('key', "customer_list");
             for (var value of formData.values()) {
                 console.log("values",value);
     
              }
             console.log("formData",formData) */

            let options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ customer_list: base64 })
            }
            this.setState({ uploading: true });
            fetch('https://chat-crm.lotusdew.in/smallcase/update_database', options)
                .then(resp => resp.json())
                .then(result => {
                    // console.log("update database:", result);
                    alert("Succesfully Uploaded!");
                    if (result.success === true) {
                        this.setState({
                            uploading: false,
                            uploadmsg: result.success
                        })
                        fetch('https://chat-crm.lotusdew.in/smallcase/last_updated')
                            .then(response => response.json())
                            .then(data => {
                                this.setState({
                                    dateTime: data.last_updated
                                })
                            })
                            .catch((error) => {
                                alert(error)
                            })
                    }
                })
                .catch((error) => {
                    alert(error)
                })

        }
        else {
            alert("Please select a File to upload!");
        }

    }

    /**
     * 
     * To first tab add a file selector with a browse button and upload button. Upload button will call an endpoint with CSV file as form data
     */
    convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    render() {
        const { file, dateTime, uploading, uploadmsg } = this.state
        //console.log("file",file)
        //console.log("this file",this.file)
        // console.log(dateTime)
        return (

            <div>
                <Smallcase />
               
            <div className={style.CardContainer}>
                    <div>
                        <input className={style.fileInput} type="file" size="60" id="file" accept="application/csv" onChange={this.handleChange} />
                        {uploading ? <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : <span className="alert-success" role="alert">File uploaded Succesfully!</span> && uploadmsg === true}
                        <br />
                        <br />
                        <button onClick={() => this.OnFileSubmit(file)} >Submit</button>
                        <br />
                        <p className={style.lastUpdated}>Last Updated:<span>{dateTime.toString()}</span></p>
                    </div>
                    <i className={`${style.myIcon} p-2 bd-highlight fa fa-info-circle`} title="upload file downloaded from smallcase website"></i>
            </div>
            </div>
        )
    }
}



export default UpdateCustList;



// <div className="sub-con">

// <div className="content">
//     <input type="file" size="60" className="form-control" id="file" accept="application/csv" onChange={this.handleChange} />
//     <br />
//     <br />
//     <button onClick={()=> this.OnFileSubmit(file)} >Submit</button>
//     <br />
//     <p className="last-update">Last Updated:<time>{dateTime.toString()}</time></p>
// </div>
//  <i className="fa fa-info-circle"  title="upload file downloaded from smallcase website"></i>  
// </div>