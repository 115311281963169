import React, { Component } from 'react'
import Smallcase from '../Smallcase/Smallcase'
import  Login  from './Login/Login'


export default class PortfolioDownload extends Component {
    render() {
        return (
            <div>
             <Smallcase/>
             <div>
                <Login/>
             </div>   
            </div>
        )
    }
}
