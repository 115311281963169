import React, { Component } from 'react';

class BotBuilderLeftPane extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

    render() {
        return <div>Bot builder left panel
        </div>
    }
}

export default BotBuilderLeftPane;