import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const KYCFetchFailed = ({ kycData}) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
  
    const exportToCSV = (kycData) => {
      console.log("I am getting api data:", kycData)
      const ws = XLSX.utils.json_to_sheet(kycData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "kyc_fetch_failed" + fileExtension);
    };
  
    return (
      <div>
      <br/>
      {
        kycData.length > 0 &&
      <button onClick={(e) => exportToCSV(kycData)} >Download Failed KYC List</button>
      
      }
      </div>
    );
  };

 