import {
    SEND_PHONE_VERIFICATION_REQUEST,
    SEND_PHONE_VERIFICATION_SUCCESS,
    SEND_PHONE_VERIFICATION_FAILED,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    USERLIST_SUCCESS,
    USERLIST_FAILED,
    USERLIST_REQUEST,
    SEND_TRADE_EMAIL_REQUEST,
    SEND_TRADE_EMAIL_FAILED,
    SEND_TRADE_EMAIL_SUCCESS
    // SEND_NEWS_LETTER_REQUEST,
    // SEND_NEWS_LETTER_SUCCESS,SEND_NEWS_LETTER_FAILED,
} from "../Constants/actionTypes.constants";
import { getFromLocalStorage } from "../utils";

export const initialStore = {
    loading: false,
    error: false,
    errorMessage: "",
    phoneOtp: '',
    kycPhone: getFromLocalStorage('phoneNumber') ? getFromLocalStorage('phoneNumber') : '',
    userDetails: {},
    tokenExpiry: false,
    userRole:'',
    adminToken:'',
    PortfolioCANorPhone: getFromLocalStorage('inputCANorPhone') ? getFromLocalStorage('inputCANorPhone') : '',
    file:'',
    send_newsletter_res:'',
    registeredUsers:[],
    fromUsers:[],
    tradeEmailresponse:[],
    failedtradeEmailresponse:'',
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case SEND_PHONE_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case SEND_PHONE_VERIFICATION_SUCCESS:
            return {
                ...state,
                kycPhone: action.phone,
                phoneOtp: '',
                loading: false,
                error: false,
            };
        case SEND_PHONE_VERIFICATION_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                phoneOtp: '',
                userDetails: {},
                error: false,
            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                phoneOtp: action.phoneOtp,
                userDetails: action.data.user_details,
                userRole: action.data.user_details.role,
                adminToken: action.data.token,
                tokenExpiry: false,
                error: false,
            };
        case VERIFY_OTP_FAILED:
            return {
                ...state,
                phoneOtp: '',
                userDetails: {},
                error: true,
            };

        case USERLIST_REQUEST:
            return {
                ...state,
                error: false,
            };
        case USERLIST_SUCCESS:
            return {
                ...state,
                error: false,
                registeredUsers: action.data.registered_users,
            };
        case USERLIST_FAILED:
            return {
                ...state,
                error: true,
            };

        case SEND_TRADE_EMAIL_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case SEND_TRADE_EMAIL_SUCCESS:
            console.log("action.data",action.data.user_list)
            return {
                ...state,
                error: false,
                tradeEmailresponse: action.data.user_list,
                loading: false,
            };
        case SEND_TRADE_EMAIL_FAILED:
            return {
                ...state,
                error: true,
                failedtradeEmailresponse: 'failed!!!',
                loading: false,

            };

        // case SEND_NEWS_LETTER_REQUEST:
        //     return {
        //         ...state,
               
        //     };
        // case SEND_NEWS_LETTER_SUCCESS:
        //     return {
        //         ...state,
        //         send_newsletter_res: action.data.message,

        //     };
        // case SEND_NEWS_LETTER_FAILED:
        //     return {
        //         ...state,
        //         send_newsletter_res: action.error.message
        //     };

        default:
            return state
    }
}
