import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom';

import style from './City.module.scss';


class Validate extends Component {
    constructor(props){
        super(props);
        this.state = {
         password:'',
         responseData:[],
         authSuccess: '',
         status:'',
        }
    }

    handleTextChange = (e) => {
        this.setState({password: e.target.value})
    }

    onValidate = () => {
        const { password } = this.state;
        const data = {
            token: password
        }
        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json' },
            body: JSON.stringify(data)   
      }
        
        fetch(
          "https://chat-crm.lotusdew.in/validateEmailCampaign",
          requestOptions
        )
          .then((res) => res.json())
          .then((response) => {
            // console.log(response.status,"response.status")
            if (response.status === "success") {
              this.setState({
                responseData: response.message,
                authSuccess: true,
                status: true,
              });
            } else {
              document.getElementById("password").style.border =
                "1px solid red";
              this.setState({ status: false });
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }


    render() {
        const { authSuccess, password, status } = this.state;
        if(authSuccess){
          localStorage.setItem("isAuthenticated", "true");
           /* return <Redirect to={{
                pathname: "/",
                authSuccess: authSuccess
            }} />*/
            localStorage.setItem("isAuthenticated", "true");
             window.location.pathname = "/";
        }
       
        return(
          
            <section className={style.validateContainer}>
               <input className={`${style.validateContainer} ${style.myinput}`} type="text" id="password" name="password" placeholder="Enter token" autoComplete="off" onChange={(e) => this.handleTextChange(e)}/>
               {status === false && <p className={style.error}>Incorrect password</p>}
               <button className={style.btn} disabled={!password}  onClick={this.onValidate}>Log In</button> 
            </section>
            
        )
    }
}

export default Validate;