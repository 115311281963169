const crypto = require('crypto');

const algorithm = 'aes-256-cbc';
const key = 'kYp3s6v9y$B&E)H+MbQeThWmZq4t7w!z';
const iv = 'hWmZq3t6w9z$C&F)';

export function encrypt(data) {
    console.log(data,"data before encryption")
    const cipher = crypto.createCipheriv(algorithm, key, iv);
    let encrypted = cipher.update(data, 'binary', 'base64');
    encrypted = encrypted + cipher.final('base64');
    return encrypted;
}

export function decrypt(data) {
    const decipher = crypto.createDecipheriv(algorithm, key, iv);
    let decrypted = decipher.update(data, 'base64', 'binary');
    decrypted = decrypted + decipher.final('binary');
    return decrypted;
}