import React, { Component } from 'react';




class Panel extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    render() {
        const { children } = this.props;
        return <div>{children}</div>
    }
}

export default Panel;