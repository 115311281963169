import React, { Component } from 'react';
import './KYCBulkDownload.scss';
import Smallcase from '../Smallcase/Smallcase';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import style from '../KYCFetch/KYCIndividualForm.module.scss';
import { IdentityDetails, AddressDetails, Instructions, DelcarationSection, OfficeUseSection } from '../KYCFetch/KYCIndividual/KYCIndividualFormUtil/KYCIndividualFormUtil';

class KYCBulkDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kycformData: [],
      load: true,
      formattedData: [],
      invalidData: []
    }
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  async fetchKycValidUser(res) {
    for (var i = 10; i < res.length; i++) {
      // for (var i = 4; i < 10; i++) {
      console.log(`calling for fetch for ${res[i]}`);
      await this.fetchData(res[i]);
      await this.sleep(5000);
    }
  }

  formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        this.processCSV(text);
      };
      reader.readAsText(file);
    }
  };

  handleDownload = () => {
    const { formattedData } = this.state;
    this.fetchKycValidUser(formattedData);
    console.log("Invalid data")
    console.log(this.state.invalidData);
  }

  processCSV = (str) => {
    const lines = str.split('\n');
    const result = [];

    // Extract header to avoid including in the result
    const headers = lines[0].split(',');

    // Start processing rows from the second line (skip the header)
    for (let i = 1; i < lines.length; i++) {
      if (!lines[i]) continue; // Skip empty rows

      const row = lines[i].split(',');
      const rowData = {
        PAN: row[0],
        DOB: this.formatDate(row[1]),
      };
      result.push(rowData);
    }
    this.setState({ formattedData: result });
    console.log(this.state.formattedData);
    alert("file upload successfull");
  };

  fetchData(data) {
    console.log(data, "data")
    const value = [data.PAN, data.DOB];

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ value: value })
    };
    fetch('https://api.lotusdew.in/kyc/fetches', requestOptions)
      .then(response => response.json())
      .then(res => {
        if (res.success === true) {
          this.setState({ kycformData: res.insert_data });
          this.setState({ load: false });
          let id = "individualkycform";
          let HTML_Width = 800;
          let HTML_Height = document.getElementById(id).offsetHeight;
          let top_left_margin = 15;
          let PDF_Width = HTML_Width + (top_left_margin * 2);
          let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
          let canvas_image_width = HTML_Width;
          let canvas_image_height = HTML_Height;

          let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

          window.scrollTo(0, 0);
          html2canvas(document.getElementById(id), { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height + 10]);
            pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

            for (let i = 1; i <= totalPDFPages; i++) {
              pdf.addPage(PDF_Width, PDF_Height);
              pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin), canvas_image_width, canvas_image_height);
            }
            pdf.save(value[0] + ".pdf");
          });
          console.log(`Download successful, saved file as ${data.PAN}.pdf`);
          // alert(`Download successful, saved file as ${data.PAN}.pdf`);
          this.setState({ PAN: "", DOB: "" });
        } 
        else {
          this.setState({
            invalidData: [...this.state.invalidData, data]
          });
          // alert(`Failed to download user KYC with PAN ${data.PAN}`);
          console.log(`Failed to download user KYC with PAN ${data.PAN}`);
        }
      })
      .catch(error => {
        this.setState({
          invalidData: [...this.state.invalidData, data]
        });
        // alert(`Failed to download user KYC with PAN ${data.PAN}`);
        console.log(`Failed to download user KYC with PAN ${data.PAN}`);
      });
  }

  render() {
    const { kycformData, load } = this.state;

    return (
      <div>
        <Smallcase />
        <div className={style.cont}>
          <div>
            <input type="file" accept=".csv" onChange={this.handleFileUpload} />
          </div>

          <div>
            <button onClick={this.handleDownload}>Start Download</button>
          </div>

          {load ? '' : (
            <div className={`${style.kycHide}`}>
              <div id="individualkycform" className={`${style.kycForm}`}>
                <div className={`${style.formBody} m-4`}>
                  <div className={`${style.headerSecGap}`}></div>
                  <div className="pt-3">
                    <div className="float-left ml-5 mr-2">
                      <span>Know Your Client(KYC) <br />
                        Application Form (For Individuals Only)</span>
                      <hr className={`${style.headerHrLine}`} />
                      <span>Please Fill in English and in BLOCK LETTERS</span>
                    </div>
                    <div className="float-left mr-2">
                      <img src="/static/images/KycindividualLogo.JPG"
                        className={`${style.fixedH67}`} alt='' />
                    </div>
                    <div className="float-left pt-2">
                      <div className={`${style.internmediaryLogo} internmediary-logo mr-3 float-left`}>
                        Place for intermediary Logo
                      </div>
                      <span>Application No.: {kycformData.application_kyc_data.application_no}</span>
                    </div>
                  </div>

                  <IdentityDetails kycformData={kycformData}></IdentityDetails>
                  <AddressDetails kycformData={kycformData}></AddressDetails>
                  <DelcarationSection kycformData={kycformData}></DelcarationSection>
                  <OfficeUseSection kycformData={kycformData}></OfficeUseSection>
                  <Instructions></Instructions>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default KYCBulkDownload;
