import React from 'react'
import html2canvas from 'html2canvas';
//import moment from 'moment';
//import { get } from "lodash";
import jsPDF from 'jspdf';
//import { postKycFormData } from '../../../Actions/kycform.actions';
import style from './KYCIndividualFormUtil.module.scss';

export function postKycForm(dispatch){
    let id="individualkycform" ;  
    let HTML_Width =  800;
    let HTML_Height =  document.getElementById(id).offsetHeight;
    let top_left_margin = 15;
    let PDF_Width = HTML_Width+(top_left_margin*2);
    let PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    let canvas_image_width = HTML_Width;
    let canvas_image_height = HTML_Height;
    
    let totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
    
  
    html2canvas(document.getElementById(id),{allowTaint:true}).then(function(canvas) {
      canvas.getContext('2d');
      
      //console.log(canvas.height+"  "+canvas.width);
      
      
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
      
      
      for (let i = 1; i <= totalPDFPages; i++) { 
        pdf.addPage(PDF_Width, PDF_Height+20);
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height*i)+(top_left_margin),canvas_image_width,canvas_image_height);
      }
      
        
      const pdfDoc = btoa(pdf.output());
      //dispatch(postKycFormData(pdfDoc));
    });
}


/*export function downloadKycForm(kycSubmissionPdf, userDetails){
        const byteCharacters = atob(kycSubmissionPdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const url = window.URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        const name = Object.keys(userDetails).length > 0  ? get(userDetails, 'name', '') === '' ? `KYC-${moment().format("DDMMYYYY")}.pdf`:  `${userDetails.name.toUpperCase()}-KYC-${moment().format("DDMMYYYY")}.pdf` : `KYC-${moment().format("DDMMYYYY")}.pdf`;
        link.setAttribute('download',  name);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        // pdf.save(id);

}*/

const SquareBoxInputFeild = (props) => {
    const charArr = Object.assign([], props.value);
    const getAllSquareAndValue = () => {
        let jsxElt = [];
        for (let inx = 0; inx < props.count; inx++) {
            jsxElt.push(
                <input type="text" className={`align-middle ${style.squareBox}`} value={charArr[inx]} maxLength={1} />

            );
        }
        return jsxElt;
    }
    return (
        <div className={`align-middle ${props.className ? props.className : ""} d-inline`}>
            {getAllSquareAndValue()}
        </div>
    );
}


const IdentityDetails = ({kycformData}) => {
    return (
        <div className={`${style.squareCard} m-4 ${style.clearBoth}`}>
            <div className={`${style.cardHeader} py-0`}><strong>A. Identity Details (please see guidelines overleaf)</strong>
            </div>
            <div className="w-100 d-flex">
                <div className="w-75 float-left">
                    <div className="font-weight-normal d-block ml-3 pl-1" ><strong>1. Name of Applicant</strong>
                        <span> (As appearing in supporting identification document).</span></div>
                    <div className="mt-1 ml-4 w-100 d-flex">
                        <div className="float-left mx-3">Name</div>
                        <div className="float-left d-inline ">
                            <SquareBoxInputFeild count={kycformData.application_kyc_data.name.length} value={kycformData.application_kyc_data.name} />
                        </div>
                    </div>
                    <div className={`${style.clearBoth} mt-3 ml-4 d-flex w-100`} >
                        <div className={`${style.w126} float-left mx-2`} >Father's/Spouse Name</div>
                        <div className="float-left d-inline ">
                            <SquareBoxInputFeild count={kycformData.application_kyc_data.father_name ? kycformData.application_kyc_data.father_name.length : kycformData.application_kyc_data.spouse_name.length}
                                value={kycformData.application_kyc_data.father_name ? kycformData.application_kyc_data.father_name : kycformData.application_kyc_data.spouse_name} />
                        </div>
                    </div>
                    <div className={`${style.clearBoth} mt-3 ml-4 d-flex w-100`} >
                        <div className="float-left mr-2 ">2.Gender</div>
                        <div className={`${style.greyColor}float-left d-inline font-weight-normal`} >
                            <CheckBox class="mr-2" label="Female"
                                value={kycformData.application_kyc_data.gender === "Female" ? true : false}></CheckBox>
                            <CheckBox class="mr-2" label="Male" value={kycformData.application_kyc_data.gender === "Male" ? true : false}></CheckBox>
                            <span className={`${style.blackColor} font-weight-bold`} >B. Maritial status</span>
                            <CheckBox class="ml-2" label="Single"
                                value={kycformData.application_kyc_data.marital_status.toLowerCase() === "unmarried" ? true : false}></CheckBox>
                            <CheckBox class="mx-2" label="Married" value={kycformData.application_kyc_data.marital_status === "Married" ? true : false}></CheckBox>
                            <span className={`${style.blackColor} font-weight-bold mr-2`}>C Date of Birth</span>
                            <SquareBoxInputFeild count={kycformData.application_kyc_data.dob.split("/")[0].length}
                                value={kycformData.application_kyc_data.dob && kycformData.application_kyc_data.dob.split("/")[0] ?
                                    kycformData.application_kyc_data.dob.split("/")[0] : "dd"} />
                            <SquareBoxInputFeild count={kycformData.application_kyc_data.dob.split("/")[1].length}
                                value={kycformData.application_kyc_data.dob && kycformData.application_kyc_data.dob.split("/")[1] ?
                                    kycformData.application_kyc_data.dob.split("/")[1] : "mm"} />
                            <SquareBoxInputFeild count={ kycformData.application_kyc_data.dob.split("/")[2].length}
                                value={kycformData.application_kyc_data.dob && kycformData.application_kyc_data.dob.split("/")[2] ?
                                    kycformData.application_kyc_data.dob.split("/")[2] : "yyyy"} />
                        </div>
                    </div>
                    <div className={`${style.clearBoth} mt-3 ml-4 d-flex w-100`} >
                        <div className="float-left mr-2 ">3.Nationality</div>
                        <div className={`${style.greyColor} float-left d-inline font-weight-normal`} >
                            <CheckBox class="mr-2" label="Indian"
                                value={kycformData.application_kyc_data.nationality === "Indian" ? true : false}></CheckBox>
                            <CheckBox class="mr-2" label="Other" value={kycformData.application_kyc_data.other_nationality === "Other" ? true : false}></CheckBox>
                        </div>
                        <hr className="w-50" />
                    </div>
                    <div className={`${style.clearBoth} mt-3 ml-4 d-flex w-100`} >
                        <div className={`${style.w151} float-left mr-2 `} ><strong>4.Status</strong><span className={`${style.greyColor} font-weight-normal`}> please tick (&#10004;)</span></div>
                        <div className={`${style.greyColor} float-left d-inline font-weight-normal`}>
                            <CheckBox class="mr-2" label="Resident individual" value={"Resident Individual" ===
                                kycformData.application_kyc_data.residential_status}></CheckBox>
                            <CheckBox class="mr-2" label="Non Resident"
                                value={"Non Resident" ===
                                    kycformData.application_kyc_data.residential_status}></CheckBox>
                            <CheckBox class="ml-2" label="Foreign National  (Passpost Copy mandatory for NRIs & Foreign Nationals)" value={"Foreign National  (Passpost Copy mandatory for NRIs & Foreign Nationals)" ===
                                kycformData.application_kyc_data.residential_status}></CheckBox>
                        </div>
                    </div>
                    <div className={`${style.clearBoth} mt-3 ml-4 d-flex w-100`} >
                        <div className="float-left mr-2 ">5. PAN</div>
                        <div className={`${style.greyColor} float-left d-inline font-weight-normal`}>
                            <SquareBoxInputFeild count={kycformData.application_kyc_data.pan_no.length} value={
                                kycformData.application_kyc_data.pan_no
                            } />
                            <span className={`${style.greyColor} ml-1`}>Please enclose a duly attested copy of your PAN card</span>
                        </div>
                    </div>
                    <div className={`${style.clearBoth} mt-3 ml-4 d-flex w-100`} >
                        <div className="float-left mr-2 ">Aadhar Number,<span className={`${style.greyColor} font-weight-normal`}>if any:</span> </div>
                        <span class={`${style.lineHeight1} w-50`} >
                            {
                                kycformData.application_kyc_data.aadhaar_number
                            }
                            <hr />
                        </span>
                    </div>
                </div>
                <div className={`${style.profileImg}  mt-3 mr-3 ${style.profilePhoto}`}>
                    {kycformData.b64_photo && <img className={`${style.profilePhoto}`} src={`data:image/jpeg;base64, ${kycformData.b64_photo}`} ></img>}
                    {!kycformData.b64_photo && (<div className={`${style.centered}`}>
                        <div className={`${style.photoGraph}`}>PHOTOGRAPH</div>
                        <div className="mt-5"><span>Please affix the recent passport size photograph and sign across it</span></div>
                    </div>)}
                    {kycformData.signature64 &&
                        <div className={`${style.sign} w-100`} >
                            <img className={`w-100`} src={`data:image/png;base64, ${kycformData.signature64}`} ></img>
                        </div>}
                </div>
            </div>
            <div className={`${style.clearBoth} mt-3 ml-4 d-flex w-100`} >
                <div className="float-left mr-2 " ><strong>6. Proof of Identity submitted for PAN exempt cases </strong><span className={`${style.greyColor} font-weight-normal`}> please tick (&#10004;)</span></div>
            </div>
            <div className={`${style.greyColor} mt-3 ml-4 font-weight-normal d-flex w-100`} >
                <CheckBox class="mr-2" label="UID(Aadhar)"
                    value={kycformData.application_kyc_data.aadhaar_number}></CheckBox>
                <CheckBox class="mr-2" label=" Passport" value={false}></CheckBox>
                <CheckBox class="ml-2" label=" Voter ID" value={false}></CheckBox>
                <CheckBox class="mr-2" label="Driving Licence" value={false}></CheckBox>
                <CheckBox class="mr-2" label=" Others" value={false}></CheckBox>
                <hr className={`${style.w40}`} />
                <span>(Please see guidelines 'D' overleaf)</span>
            </div>

        </div>);
}



const AddressDetails = ({kycformData}) => {
    let perAddressproof = kycformData.application_kyc_data.per_add_proof;
    let corAddressproof = kycformData.application_kyc_data.cor_add_proof;


    return (<div className={`${style.squareCard} ${style.clearBoth} m-4 ${style.h670}`}>
        <div className={`${style.cardHeader} py-0`}><strong>B. Address Details (please see guidelines overleaf)</strong>
        </div>
        <div>
            <div className="font-weight-normal d-block ml-3 pl-1" ><strong>1. Address for Residence/Correspondence</strong>
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.per_add1?kycformData.application_kyc_data.per_add1.length : 3 } value={kycformData.application_kyc_data.per_add1} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.per_add2?kycformData.application_kyc_data.per_add2.length : 3 } value={kycformData.application_kyc_data.per_add2} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.per_add3?kycformData.application_kyc_data.per_add3.length : 3 } value={kycformData.application_kyc_data.per_add3} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>City/Town/Village</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.per_add_city.length} value={kycformData.application_kyc_data.per_add_city} />
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>Pin Code</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.per_add_pincode.length} value={kycformData.application_kyc_data.per_add_pincode} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom pr-1`}>State</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.per_add_state.length} value={kycformData.application_kyc_data.per_add_state} />
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom pr-1`}>Country</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.per_add_country.length} value={kycformData.application_kyc_data.per_add_country} />
            </div>
            <div className="font-weight-normal d-block ml-3 pl-1 pt-2" ><strong>2.Contact Details</strong>
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>Tel. (Off)</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(ISD)</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(STD)</span>
                <SquareBoxInputFeild count={25} value={kycformData.application_kyc_data.office_no} />
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>Tel. (Res.)</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(ISD)</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(STD)</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.residential_no.length} value={kycformData.application_kyc_data.residential_no} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>Mobile</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(ISD)</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(STD)</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.mobile_no.length} value={kycformData.application_kyc_data.mobile_no} />
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>Fax</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(ISD)</span>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>(STD)</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.fax_no.length} value={kycformData.application_kyc_data.fax_no} />
            </div>

            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom px-2`}>E-MailId. </span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.email.length} value={kycformData.application_kyc_data.email} />
            </div>

            <div className="font-weight-normal d-block ml-3 pl-1 pt-2" ><strong>3. Proof of address to be provided by Applicant. Please submit ANY ONE of the following valid documents & tick (&#10004;) against the document attached.</strong>
            </div>
            <div className={`${style.greyColor} ml-4 font-weight-normal d-flex w-100`}  >
                <CheckBox class="mr-2" label="Passport" value={perAddressproof === "Passport" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="Ration Card" value={perAddressproof === "Ration Card" ? true : false}></CheckBox>
                <CheckBox class="ml-2" label="Registered Lease/Sale Argeement of Residence" value={perAddressproof === "Registered Lease/Sale Argeement of Residence" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="Driving License" value={perAddressproof === "Driving License" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="Voter Identity Card" value={perAddressproof === "Voter Identity Card" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="*Latest Bank A/c Statement/Passbook" value={perAddressproof === "*Latest Bank A/c Statement/Passbook" ? true : false}></CheckBox>
            </div>
            <div className={`${style.greyColor} ml-4 font-weight-normal d-flex w-100`}  >
                <CheckBox class="mr-2" label="*Latest Telephone Bill(only Land Line)" value={perAddressproof === "*Latest Telephone Bill(only Land Line)" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="*Latest Electricity Bill" value={perAddressproof === "*Latest Electricity Bill" ? true : false}></CheckBox>
                <CheckBox class="ml-2" label="*Latest Gas Bill" value={perAddressproof === "*Latest Gas Bill" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label=" Others" value={(perAddressproof !== "Passport" && perAddressproof !== "Ration Card" && perAddressproof !== "Registered Lease/Sale Argeement of Residence" &&  perAddressproof !== "Driving License" && perAddressproof !== "Voter Identity Card" && perAddressproof !== "*Latest Bank A/c Statement/Passbook" && perAddressproof !== "*Latest Telephone Bill(only Land Line)" && perAddressproof !== "*Latest Electricity Bill" && perAddressproof !== "*Latest Gas Bill") ? true : false}></CheckBox>

                <span className={`${style.otherAddressDoc}`}>(Please specify)<span>{(perAddressproof !== "Passport" && perAddressproof !== "Ration Card" && perAddressproof !== "Registered Lease/Sale Argeement of Residence" &&  perAddressproof !== "Driving License" && perAddressproof !== "Voter Identity Card" && perAddressproof !== "*Latest Bank A/c Statement/Passbook" && perAddressproof !== "*Latest Telephone Bill(only Land Line)" && perAddressproof !== "*Latest Electricity Bill" && perAddressproof !== "*Latest Gas Bill") ? perAddressproof : ""}</span></span>
            </div>
            <div className={`${style.greyColor} ml-4 font-weight-normal d-flex w-100`}  >
                <span className="ml-2">*Not more than 3 Month old. <strong>Validity/Expire date of proof of address submitted</strong></span>
                <SquareBoxInputFeild count={2}
                                value={kycformData.application_kyc_data.validity_of_per_add_proof && kycformData.application_kyc_data.validity_of_per_add_proof.split("/")[0] ?
                                    kycformData.application_kyc_data.validity_of_per_add_proof.split("/")[0] : ""} />
                            <SquareBoxInputFeild count={2}
                                value={kycformData.application_kyc_data.validity_of_per_add_proof && kycformData.application_kyc_data.validity_of_per_add_proof.split("/")[1] ?
                                    kycformData.application_kyc_data.validity_of_per_add_proof.split("/")[1] : ""} />
                            <SquareBoxInputFeild count={4}
                                value={kycformData.application_kyc_data.validity_of_per_add_proof && kycformData.application_kyc_data.validity_of_per_add_proof.split("/")[2] ?
                                    kycformData.application_kyc_data.validity_of_per_add_proof.split("/")[2] : ""} />
            </div>

            <div className="font-weight-normal d-block ml-3 pl-1" ><strong>4. Permanent Address of Resident Applicant if different from above B1 OR Overseas Address(Mandatory) for Non-Resident Applicant</strong>
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.cor_add1 ? kycformData.application_kyc_data.cor_add1.length : 3} value={kycformData.application_kyc_data.cor_add1} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.cor_add2 ? kycformData.application_kyc_data.cor_add2.length : 3} value={kycformData.application_kyc_data.cor_add2} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.cor_add3 ? kycformData.application_kyc_data.cor_add3.length : 3 } value={kycformData.application_kyc_data.cor_add3} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>City/Town/Village</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.cor_add_city.length} value={kycformData.application_kyc_data.cor_add_city} />
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom`}>Pin Code</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.cor_add_pincode.length} value={kycformData.application_kyc_data.cor_add_pincode} />
            </div>
            <div className={`${style.w97} mt-1 mx-4 d-flex`}>
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom pr-1`}>State</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.cor_add_state.length} value={kycformData.application_kyc_data.cor_add_state} />
                <span className={`${style.greyColor} font-weight-normal border-left border-right border-bottom pr-1`} >Country</span>
                <SquareBoxInputFeild count={kycformData.application_kyc_data.cor_add_country.length} value={kycformData.application_kyc_data.cor_add_country} />
            </div>

            <div className="font-weight-normal d-block ml-3 pl-1 pt-2" ><strong>5. Proof of address to be provided by Applicant. Please submit ANY ONE of the following valid documents & tick (&#10004;) against the document attached.</strong>
            </div>
            <div className={`${style.greyColor} ml-4 font-weight-normal d-flex w-100`}  >
                <CheckBox class="mr-2" label="Passport" value={corAddressproof === "Passport" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="Ration Card" value={corAddressproof === "Ration Card" ? true : false}></CheckBox>
                <CheckBox class="ml-2" label="Registered Lease/Sale Argeement of Residence" value={corAddressproof === "Registered Lease/Sale Argeement of Residence" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="Driving License" value={corAddressproof === "Driving License" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="Voter Identity Card" value={corAddressproof === "Voter Identity Card" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="*Latest Bank A/c Statement/Passbook" value={corAddressproof === "*Latest Bank A/c Statement/Passbook" ? true : false}></CheckBox>
            </div>
            <div className={`${style.greyColor} ml-4 font-weight-normal d-flex w-100`}  >
                <CheckBox class="mr-2" label="*Latest Telephone Bill(only Land Line)" value={corAddressproof === "*Latest Telephone Bill(only Land Line)" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label="*Latest Electricity Bill" value={corAddressproof === "*Latest Electricity Bill" ? true : false}></CheckBox>
                <CheckBox class="ml-2" label="*Latest Gas Bill" value={corAddressproof === "*Latest Gas Bill" ? true : false}></CheckBox>
                <CheckBox class="mr-2" label=" Others" value={(corAddressproof !== "Passport" && corAddressproof !== "Ration Card" && corAddressproof !== "Registered Lease/Sale Argeement of Residence" &&  corAddressproof !== "Driving License" && corAddressproof !== "Voter Identity Card" && corAddressproof !== "*Latest Bank A/c Statement/Passbook" && corAddressproof !== "*Latest Telephone Bill(only Land Line)" && corAddressproof !== "*Latest Electricity Bill" && corAddressproof !== "*Latest Gas Bill") ? true : false}></CheckBox>

                <span className={`${style.otherAddressDoc} `}>(Please specify){(corAddressproof !== "Passport" && corAddressproof !== "Ration Card" && corAddressproof !== "Registered Lease/Sale Argeement of Residence" &&  corAddressproof !== "Driving License" && corAddressproof !== "Voter Identity Card" && corAddressproof !== "*Latest Bank A/c Statement/Passbook" && corAddressproof !== "*Latest Telephone Bill(only Land Line)" && corAddressproof !== "*Latest Electricity Bill" && corAddressproof !== "*Latest Gas Bill") ? corAddressproof : ""}</span>
            </div>
            <div className={`${style.greyColor} ml-4 font-weight-normal d-flex w-100`}  >
                <span className="ml-2">*Not more than 3 Month old. <strong>Validity/Expire date of proof of address submitted</strong></span>
                <SquareBoxInputFeild count={2}
                                value={kycformData.application_kyc_data.validity_of_cor_add_proof && kycformData.application_kyc_data.validity_of_cor_add_proof.split("/")[0] ?
                                    kycformData.application_kyc_data.validity_of_cor_add_proof.split("/")[0] : ""} />
                            <SquareBoxInputFeild count={2}
                                value={kycformData.application_kyc_data.validity_of_cor_add_proof && kycformData.application_kyc_data.validity_of_cor_add_proof.split("/")[1] ?
                                    kycformData.application_kyc_data.validity_of_cor_add_proof.split("/")[1] : ""} />
                            <SquareBoxInputFeild count={4}
                                value={kycformData.application_kyc_data.validity_of_cor_add_proof && kycformData.application_kyc_data.validity_of_cor_add_proof.split("/")[2] ?
                                    kycformData.application_kyc_data.validity_of_cor_add_proof.split("/")[2] : ""} />
            </div>
            <div className="mt-3 ml-4 font-weight-normal d-flex w-100"  >6. Any Other Information:
                <span class={`${style.w60}`} >
                    {
                        kycformData.application_kyc_data.other_information
                    }
                    <hr />
                </span>
            </div>

        </div>
    </div>);
}

const CheckBox = (props) => {
    return (<div className={`d-inline ${props.class}`}>
        <input className={`align-middle mx-1 ${props.classInput}`} type="checkbox" id={`${props.label}-${props.value}`} name={`${props.label}-${props.value}`}
            checked={props.value} />
        <label for={props.label} className={`${props.classLabel}`}>{props.label}</label>
    </div>
    );
}


const Instructions = () => {
    return (
        <div className={`${style.instruction} mx-3 mt-n3`}>
            <div className={`mx-4 ${style.instructionHead}`}> INTRUCTIONS/CHECK LIST FOR FILLING KYC FORM</div>
            <div className={`${style.blackColor} mx-4`}>
                <div className={`${style.w45} float-left`}>
                    <div className="font-weight-bold">A. IMPORTANT POINTS:</div>
                    <div className="font-weight-normal ml-3">
                        <ol>
                            <li>Self arrested copy of PAN card is mandatory for all clients</li>
                            <li>Copies of all the documents submitted by the application
                            should be self-attested and accompained by originals for verification, then the copies should be properly attested
                            by entities authorized for attesting the documents, as
per the below mentioned list.</li>
                            <li>if any proof of identity or address is in a foreign
                            language, then translation into english is required.
4.Name & address of the application mentioned on the KYCform, should match with the documentary proof submitted.</li>
                            <li>if correspondence & permanent address are different,
the proofs for both have to be submitted.</li>
                            <li>Sole proprietor must make the appliction in his individual name & capacity</li>
                            <li>For non-residents and foreign nationals,(allowed to trade subject to RBI and FEMA guidelines), copy of passport/
PIOCard/OCIcard and overseas address proof is mandatory.</li>
                            <li>For foreign entities, CIN is optional; and in the absence of DIN no. for the directors, their passport copy should be given.</li>
                            <li>In case of Merchant Navy NRI's, Mariner's declaration or certified copy of CDC (Continuous Discharge Certificate) is to be submitted.</li>
                            <li>For opening and account with Depository participant or Mutual Fund,For a minor,photocopy of the school Leaving Certificate/Mark sheet issued by Higher secondary Board/Passport of Minor/Birth Certificate must be provided.</li>
                            <li>Politically Exposed Persons(PEP) are defined as
                            individuals who are or have been entrusted with prominent
                            public functions in a foreign country. e.g. Heads of states
                            or of Goverments, senior politicians, senior politicians,
senior Goverment/judicial/military officers, senior executives of state owned corporations, important political party officials, etc.</li>
                        </ol>
                    </div>

                    <div className="font-weight-bold">B. proof of Identity(POI): List of documents admissible as Proof of Identity:</div>
                    <div className="font-weight-normal ml-3">
                        <ol>
                            <li>PAN card with photograph. This is a mandatory requirement for all applicants expect those who are specifically exempt from obtaining PAN(listed in Section D)</li>
                            <li>unique Iddentification Number (UID)(Aadhar)/Passport/Voter ID card/Driving License.</li>
                            <li>Identity card/Document with applicant's photo, issued by the any of the following: Central/State Goverment and its Departments, Statutory/Regulatory Authorities, Public Sector Undertakings, Scheduled Commercial Banks, Public Financial Instutions, Colleges affiliated to universities, Professional Bodies such as ICAI, ICWAI, ICSI
, Bar Council etc, to their Members; and Credit cards/Debit cards issued by Banks.</li>
                        </ol>
                    </div>

                    <div className="font-weight-bold">C. Proof of Address(POA):List of documents admissible as Proofof Address:(Documents having an expiry date should be valid on the date of submission.)</div>

                    <div className="font-weight-normal ml-3">
                        <ol>
                            <li>Passport/Voters identity Card/Ration Card/Registered Lease or Sale Aggreement of Residence/Driving License/Flat maintenance bill/Insurance Copy.</li>

                        </ol>
                    </div>
                </div>
                <div className={`${style.w45} float-left`}>
                    <div className="font-weight-normal ml-3">
                        <ol start="2">
                            <li>Utility bills like Telephone Bill(only land line), Electricity bill or Gas bill Not more than 3 months old.</li>
                            <li>Bank Account Statement/Passbook- Not more than 3 months old.</li>
                            <li>Self-declaration by High Court and Supreme Court judges, giving the new address in respet of their own accounts.</li>
                            <li>Proof of address issued by any of follwoing: bank managers of Scheduled Commercial Banks/Scheduled Co-Operative bank/Multinational Foreign Banks/Gazetted Officier/Notary
public/Elected representatives to the Legislative Assembly/Parliament/Documents issued by any Givt. or Statutory/Regulatory Authority.</li>

                            <li>Identity Card/document with address, issued by any of the following: Central/State Goverment and its Departments,
Statutory/Regulatory Authorities,Public Sector Undertakings,Scheduled Commercial Banks, Public Financial Instutions, Colleges affiliated to Universities and Professional Bodies such as ICAI, ICWAI, ICSI, bar Council etc.to their Members.</li>
                            <li>For FII/sub account, Power of Atterney given by FII/sub-account to the Custodians (which are duly notarized and/or apostiled or consularied) that gives the registered address should be taken.</li>
                            <li>The proof of address in the name of the spouse may be accepted.</li>
                        </ol>
                    </div>

                    <div className="font-weight-bold">D.Exemptions/clarifications to PAN(*Sufficient documentary
evidence in support of such claims to be collected.)</div>
                    <div className="font-weight-normal ml-3">
                        <ol>
                            <li>In case of transactions undertaken on behalf of Central Goverment and/or State Goverment and by officials appointed by Courts e.g. Official liquidator, Court receiver etc.</li>
                            <li>Investors residing in the state of Sikkim.</li>
                            <li>UN entities/multilateral agencies expempt from paying taxes/filing tac returns in india.</li>
                            <li>SIP of Mutual Funds upto RS 50,000/-p.absence</li>
                            <li>in case of institutional clients, namely, FIIs, Mfs, VCFs, FVCIs, Scheduled Coomercial Banks, Multilateral and Bilateral Development Financial Instutions, State Industrial Development Corporations, Insurance Companies registered with IRDA and public Financial Instutions as defined under  section 4A of the Companies Act, 1956, Custodians shall verify the PAN card details with the originals PAN card provided duly certified copies of such verified PAN details to the inteermediary.</li>

                        </ol>
                    </div>

                    <div className="font-weight-bold">List of people authorized to attest the documents:</div>
                    <div className="font-weight-normal ml-3">
                        <ol>
                            <li>natory Public, Gazetted offcier, Manager of a Scheduled
Commercial/Co-Operative Bank or multinational Foreign banks(name, Designation & Seal should be affixed on the copy).</li>
                            <li>in case of NRIs, authorized officials of overseas branches of Scheduled Commercial Banks registered in india, Notary public, Court Magistrate, Judge, Indian Embassy/Consulate general in the country where the client resides are permitted to attest the documents.</li>

                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
}

const DelcarationSection = ({kycformData}) => {
    return (
        <div className={`${style.squareCard}  m-4 ${style.clearBoth} ${style.h123}`}>
            <div className={`${style.cardHeader} py-0 d-inline float-left text-center ${style.w73}`} ><strong>DECLARATION</strong>
                <div className={`${style.greyColor} ${style.bcWhite} text-left font-weight-normal pl-4`}>I hereby delcare that the details furnished above are true and correct to the best if my/our knowledge and belief and I Undertake to inform you of any changes therein, immediately. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am/we are aware that I/we may be held liable for it.</div>

                <div className={`${style.bcWhite} w-50 float-left text-left pl-4`}><label for="place">place:</label>
                    <input className="align-middle mx-1" type="text" value={kycformData.application_kyc_data.place_of_declaration}
                    /></div>
                <div className={`${style.bcWhite} w-50 float-left text-left`}><label for="place">Date:</label>
                    <input className="align-middle mx-1" type="text" value={kycformData.application_kyc_data.date_of_declaration}
                    /></div>
            </div>
            <div className={`${style.cardHeader} py-0 w-25  d-inline float-left ml-3 text-center`}><strong>SIGNATURE OF APPLICANT</strong>
                <div className={`${style.singatureBox} ${style.bcWhite}`}>
                    {/* <img className={`w-100`} src={`data:image/png;base64, ${kycformData.signature64}`} ></img> */}
                </div>
            </div>
        </div>
    );
}


const OfficeUseSection = ({kycformData}) => {
    return (
        <div className={`${style.squareCard} m-4 ${style.clearBoth} ${style.h184}`} >
            <div className={`${style.cardHeader} py-0`}>
                <div className="w-100 font-weight-normal">
                    <div className={`${style.w67} float-left ml-3`} ><strong className="text-center">FOR OFFICE USE ONLY   </strong>

                        <div  className={`${style.w52} float-left`} >
                            <span>AMC/Intermediary name <strong>OR</strong> Code</span>
                            <br/>2500013770
                            <hr className="w-100" />
                            <CheckBox class="mr-4" label="(Originals Verified) Self Certified Document copies received" value={kycformData.application_kyc_data.document_type==="Self Certified Copies Submitted (Originals Verified)"?true:false}></CheckBox>
                            <CheckBox class="mr-2 float-left"  classLabel="float-left" classInput="float-left"  label="(Attested) True copies of docmuments received main Intermediary" value={kycformData.application_kyc_data.document_type==="True Copies of Documents Received"?true:false}></CheckBox>
                        </div>
                        <div className={`${style.w48} float-left`}>

                            <div className={`${style.profileImg} mt-3 ml-4 w-100 ${style.h127}`}>
                            {kycformData.stamp64 && (<><img className={`${style.imgSize}`} src={`data:image/jpeg;base64, ${kycformData.stamp64}`}></img><div className="zindex-dropdown position-absolute text-center w-100" style={{ top: "66px",fontSize: "23px"}}>{new Date().toLocaleDateString()}</div></>)}
                                {!kycformData.stamp64 && (<div className={`${style.centered}`}>
                                    <div className={`${style.sealStamp}`} >Seal/Stamp of the intermediary should contain
                        <span className="text-center">staff Name</span>
                                        <span className="text-center"> Designation name of the Orgination Signature Date</span>
                                    </div>

                                </div>)}
                            </div>
                        </div>
                    </div>
                    <div className={`${style.w33} float-left`}>
                        <CheckBox class="mr-2" label="IPV Done" value={false}></CheckBox><span className="mr-2 ml-5">On</span>
                        <SquareBoxInputFeild count={2}
                            value={kycformData.application_kyc_data.ipv_date && kycformData.application_kyc_data.ipv_date.split("/")[0] ?
                                kycformData.application_kyc_data.ipv_date.split("/")[0] : "dd"} />
                        <SquareBoxInputFeild count={2}
                            value={kycformData.application_kyc_data.ipv_date && kycformData.application_kyc_data.ipv_date.split("/")[1] ?
                                kycformData.application_kyc_data.ipv_date.split("/")[1] : "mm"} />
                        <SquareBoxInputFeild count={4}
                            value={kycformData.application_kyc_data.ipv_date && kycformData.application_kyc_data.ipv_date.split("/")[2] ?
                                kycformData.application_kyc_data.ipv_date.split("/")[2] : "yyyy"} />
                        <div className={`${style.profileImg} mt-2 ml-4 w-100 ${style.h127}`}>
    {kycformData.stamp64 && (<><img className={`${style.imgSize}`} src={`data:image/jpeg;base64, ${kycformData.stamp64}`}></img><div className="zindex-dropdown position-absolute text-center w-100" style={{top: "66px",fontSize: "23px"}}>{new Date().toLocaleDateString()}</div></>)}
                                {!kycformData.stamp64 &&  (<div className={`${style.centered}`}>
                                <div className={`${style.sealStamp}`}>Seal/Stamp of the intermediary should contain
                        <span className="text-center">staff Name</span>
                                    <span className="text-center"> Designation name of the Orgination Signature Date</span>
                                </div>

                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export { SquareBoxInputFeild, OfficeUseSection, IdentityDetails, CheckBox, AddressDetails, Instructions, DelcarationSection };

