import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export const InvData = ({ apiData}) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".csv";
  
    const exportToCSV = (apiData) => {
      console.log("I am getting api data:", apiData)
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "invalid_user_data" + fileExtension);
    };
  
    return (
      <div>
      {
        apiData.length > 0 &&
      <button onClick={(e) => exportToCSV(apiData)}>Download Invalid Data</button>
      
      }
      </div>
    );
  };

 