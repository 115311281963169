export const SEND_PHONE_VERIFICATION_REQUEST = 'SEND_PHONE_VERIFICATION_REQUEST';
export const SEND_PHONE_VERIFICATION_SUCCESS = 'SEND_PHONE_VERIFICATION_SUCCESS';
export const SEND_PHONE_VERIFICATION_FAILED = 'SEND_PHONE_VERIFICATION_FAILED';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';
export const TOKEN_EXPIRY = 'TOKEN_EXPIRY';

export const USERLIST_REQUEST = 'USERLIST_REQUEST';
export const USERLIST_SUCCESS = 'USERLIST_SUCCESS';
export const USERLIST_FAILED = 'USERLIST_FAILED';

export const SEND_TRADE_EMAIL_REQUEST = 'SEND_TRADE_EMAIL_REQUEST';
export const SEND_TRADE_EMAIL_SUCCESS = 'SEND_TRADE_EMAIL_SUCCESS';
export const SEND_TRADE_EMAIL_FAILED = 'SEND_TRADE_EMAIL_FAILED';


// export const SEND_NEWS_LETTER_REQUEST = "SEND_NEWS_LETTER_REQUEST";
// export const SEND_NEWS_LETTER_SUCCESS = "SEND_NEWS_LETTER_SUCCESS";
// export const SEND_NEWS_LETTER_FAILED = "SEND_NEWS_LETTER_FAILED";