import React, { Component } from 'react';
// import { Redirect, withRouter } from 'react-router-dom';
import { modalStyle, mainStyle, colourStyles } from "../../city/utils/style";
import NewsLetterPanel from './NewsLetterPanel';
// @ts-ignore
import Select from 'react-select';
import Panel from "../../city/Panel";

import { footerHtml, buttonHtml, getHtmlElement,blogFooter } from "../../city/utils/footer";
import PostData from "./data/post";
import 'react-table-6/react-table.css'
import style from './SendNewsLetter.module.scss';
import SendNewsLetterTabs from './SendNewsLetterTabs'

import SendEmailEditorContent from "./SendEmailEditorContent";
import SendEmail from './SendEmail'
import Smallcase from '../Smallcase';
import { isEmpty } from 'lodash';
import SendNewsLetterModal from './SendNewsLetterModal';
import SendNewsLetterModalContent from './SendNesLetterModalContent';
import Tabs from '../../city/Tabs';
// import { sendnewsletter } from '../../../Actions/app.actions'
import { connect } from 'react-redux';

type Props = {
    sendnewsletter: (value: any, successCallback: any, failureCallback: any) => any;
    send_newsletter_res: string
}

type State = {
    selectedOption: any,  
    selectedType: string,
    fromName:string,
    fromEmail:string,
    subject:string,
    cityData: any,
    testHtml:string,
    toggleList: boolean,
    rawHtml:string,
    emailText: string,
    selectedCategoryOption:any,
    selectedSubcription:any,
    responseData: string,
    file:any,
    file_preview: string,
    uploading: boolean,
    uploadmsg:string,
    footerRender:string,
    loading: boolean,
    error: boolean,
    isModalOpen: boolean,
    selectedSmallCase:any,
    fileSmallCase:any,
}
const customStyles = {
    container: (base:any, state:any) => ({
        ...base,
        width: `80%`
      }),
      valueContainer:(base:any, state:any)=> ({
        ...base,
      }),
      multiValue:(base:any, state:any) => ({
        ...base,
        backgroundColor: "#f4f7f8"
      }),
      indicatorSeparator:(base:any, state:any)=> ({
        ...base,
        backgroundColor: "#fff"
      }),
      dropdownIndicator:(base:any, state:any)=> ({
        ...base,
        color: "#000"
      }),
      // option: (base, state) => ({
      //   ...base,
      //   color: "#f4f7f8 !important",
      //   backgroundColor: '#e8eeef !imporatnt'
      // }),
    menu: (styles:any) => ({ ...styles, backgroundColor: '#e8eeef' }),
    control: (styles:any) => ({ ...styles, backgroundColor: '#e8eeef' }),
     option: (styles:any) => ({ ...styles, color: '#f4f7f8 !imporatnt' }),
  };
class SendNewsLetter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { 
            selectedOption: null,  
            selectedType: 'email',
            fromName:'Abhishek Banerjee',
            fromEmail:'investor.relations@lotusdew.in',
            subject:'',
            cityData: [],
            testHtml:footerHtml,
            toggleList: false,
            rawHtml:'',
            emailText: '',
            selectedCategoryOption:[],
            selectedSubcription:[],
            responseData: '',
            file:null,
            file_preview: '',
            uploading: false,
            uploadmsg:'',
            footerRender:blogFooter,
            loading:false,
            error:false,
            isModalOpen:false,
            selectedSmallCase:"",
            fileSmallCase:''
        }
        this.FilehandleChange = this.FilehandleChange.bind(this);
        this.OnFileSubmit = this.OnFileSubmit.bind(this);
        this.sendNewsLetterSuccess = this.sendNewsLetterSuccess.bind(this);
        this.sendNewsLetterFailed = this.sendNewsLetterFailed.bind(this);
    }

    componentDidMount() {
    
        this.setState({cityData: [ 
            {label:"Test","value" : "Test"  }, 
            {label:"Latest","value" : "Latest"  }, 
            { label:"All", "value" : "All"  }
        ]});
    }

  

    

    onSave = () => {
        const {  selectedOption, fromName, subject, toggleList, rawHtml, testHtml,
             fromEmail, emailText,selectedSmallCase} = this.state;

        
        let finalHtml = testHtml;
        console.log(emailText,"Email Text")
        // if(testHtml === '' && emailText !== ''){
            finalHtml = getHtmlElement(emailText, footerHtml)
        // }
        const htmlString = toggleList ? rawHtml: finalHtml;
        console.log(htmlString,"finalHtml")
        const totalData = {
            subject: subject,           
            sender_email: fromEmail,
            sender_name: fromName,
            city: selectedOption.value,
            html_string:htmlString,
            smallcase_name:selectedSmallCase.value
        }
        // console.log(totalData,"totalData")
        // console.log(selectedOption,"selected")
        this.setState({ loading: true });

        // const { sendnewsletter } = this.props;

        // sendnewsletter(totalData,this.sendNewsLetterSuccess, this.sendNewsLetterFailed)
    
        let requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json' },
            body: JSON.stringify(totalData)
        }
            // console.log("requestOptions",requestOptions)
        
        fetch(
            "https://chat-crm.lotusdew.in/smallcase/send_newsletter"
             ,
              requestOptions)
           .then((res) => res.json())
           .then((response) => {
             this.setState({
               responseData: response.message,
               loading: false,
               error: false,
             });
             this.openModal();
           })
           .catch((error) => {
             this.setState({
               loading: false,
               error: true,
               responseData: error.message,
             });
             this.openModal();
           }); 

    }

    sendNewsLetterSuccess = () => {
        const { send_newsletter_res } = this.props;
        this.setState({
            loading: false,
            error: false,
            responseData: send_newsletter_res
        })
        this.openModal();
    };

    sendNewsLetterFailed = () => {
        const { send_newsletter_res } = this.props;
        this.setState({
            loading: false,
            error: true,
            responseData: send_newsletter_res
        })
        this.openModal();
    };


    handleChange = (selectedOption:any) => {

        this.setState({
            selectedOption: selectedOption
        }) 
    };
    handleFileSmallCase=(selectedOption:any) => {
        this.setState({
            fileSmallCase: selectedOption
        }) 
    }
    handleSmallCaseChange = (selectedOption:any) => {

            this.setState({
                selectedSmallCase: selectedOption
            }) 
        };


    handleTextChange = (e:any, type:any) => {

        switch (type) {
            case "subject":
                this.setState({
                    subject: e.target.value
                });
                break;
            case "from-name":
                this.setState({
                    fromName: e.target.value
                });
                break;
            case "from-email":
                this.setState({
                    fromEmail: e.target.value
                });
                break;
            default:
                console.log("unknown category");
                break;
        };

    }

    

    saveTextEditorContent = (content:any) => {
        console.log("content:",content)
        var len = content.length;
              

        const totalData = {
            html_string: content,
        }

        localStorage.setItem('emailDraft', JSON.stringify(totalData));
    }

    onChange = (content:any) => {
    
        // console.log(content,"content")
        // console.log(content,"content");
        // console.log(editor,"editor")
         const { selectedType } = this.state;
        // console.log(content,"content")
        //  var p = document.createElement("p");
        // p.innerHTML = content;
        // let text = p.innerText.replace(/\r?\n|\r/g, "<br/>");
        // console.log("text",text);
        // console.log(p.innerText,"p.innnerText");
        // let email_Text = p.innerText

        if (selectedType === 'email') {
                    
                    this.saveTextEditorContent(content);
                    this.setState({
                        emailText:content,
                        testHtml: getHtmlElement(content, footerHtml),
                        footerRender: getHtmlElement(content, footerHtml),
                       
                    });
                }
                else {
                    this.setState({
                        emailText: content,
                        footerRender: content,
                       
                    });
                }

    }

   
    onToggle = () => {
        const { toggleList } = this.state;
        this.setState({ toggleList: !toggleList });
    }

    onChangeRawHtMl = (e:any) => {
        // console.log("this onChangeRawHtMl",e.target.value)
        this.setState({ rawHtml: e.target.value });
    }

    closeModal = () => {
        const { responseData, selectedType, error } = this.state;
        this.setState({
            isModalOpen: false,
            responseData:'',
            //savedData:'',
        });
        if(selectedType === 'email' && error === true) {
        //   console.log("failed to send email.");
        }
    }

    openModal = () => {
        this.setState({
            isModalOpen: true
        });
    }

    handleChangeTypes = (selectedOption:any) => {
        console.log(selectedOption,"selected options")
        // const { formatStartDate } = this.state;
        if(selectedOption.value === 'email'){
            this.getTextEditorContent();
        }
        
        this.setState({selectedType: selectedOption.value});
    }


    getTextEditorContent = () => {
        const draftExists = localStorage.getItem('emailDraft') !== null;
        if(draftExists){
            const emailContent:any =  localStorage.getItem('emailDraft');
            const finalContent = JSON.parse(emailContent);
            this.setState({emailText : finalContent.html_string, footerRender: getHtmlElement(finalContent.html_string, footerHtml) })
        }
        else {
            this.setState({footerRender: footerHtml})
        } 
    }

    FilehandleChange(e:any) {
        
        var fileInput:any = 
                document.getElementById('file');
              
            var filePath = fileInput.value;
          
            // Allowing file type
            var allowedExtensions = 
                    /(\.pdf)$/i;
              
            if (!allowedExtensions.exec(filePath)) {
                alert('Invalid file type, Please upload PDF fle only!');
                fileInput.value = '';
                return false;
            } 
            else{
        
        // console.log(e.target.files[0])
        const files = e.target.files;
        if (files && files[0]) this.setState({ 
            file: files[0],
           
        });
    }

    };

   async OnFileSubmit(file:any) {
const {fileSmallCase}=this.state;
     // console.log("file in onFileSubmit:",file.name)
        if (file !== null){

            const base64 = await this.convertBase64(file);
            // console.log("base64",base64)
   
        /*    let formData = new FormData();
            console.log("file",file)
            formData.append('key', "newsletter");
            formData.append('file', file);
            for (var value of formData.values()) {
                console.log("values",value);
            }
        console.log("formData",formData) */

        let options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ newsletter:base64,smallcase_name:fileSmallCase.value })
        }



        this.setState({uploading: true})  
        fetch("https://chat-crm.lotusdew.in/smallcase/upload_newsletter", options)  
        .then(resp => resp.json())
            .then(result => {
                
                // alert(result.message)
                this.setState({
                    uploading: false,
                    uploadmsg: result.message
                })
                
            }) 
        }

        else{
            alert("Please select a File to upload!");
        }
     
    }

    convertBase64(file:any){
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
    
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };



    render() {
        const {footerRender,responseData,selectedType,uploading, uploadmsg,file,selectedOption,subject, fromEmail,fromName, rawHtml, emailText, toggleList,  cityData,selectedSmallCase,fileSmallCase } = this.state;
        const EditorName = 'email';
        // console.log(fromName,"fromName")
        // console.log(subject,"subject")
        // console.log(!fromEmail,"fromEmail")
        // console.log(!rawHtml,"rawHtml")
        // console.log(!emailText,"emailText")
        const smallCaseOptions = [
          { label: "Listed Venture Capital", value: "Listed Venture Capital" },
          { label: "Nanocap Champs", value: "Nanocap Champs" },
          {
            label: "ESG Core Portfolio",
            value: "ESG Core Portfolio",
          },
          {
            label: "Deep Momentum",
            value: "Deep Momentum",
          },
        ];
         const condition =  (fromName !=="" && subject !== "" && fromEmail !== "" && (rawHtml !== '' || emailText !=='')) ? true : false
        //console.log(condition,"condition")
         return (
            <div>
             <Smallcase />
             <hr />
            <div className={style.newsletterContainer}>
            <SendNewsLetterModal
                    isModalOpen={this.state.isModalOpen}
                    closeModal={this.closeModal}
                    style={modalStyle}
                >
                    <SendNewsLetterModalContent
                        selectedType={selectedType}
                        responseData={responseData}
                        //feedbackTypes={feedbackTypes}
                        //handleChangeSentiment={this.handleChangeSentiment}
                        //callStatus={callStatus}
                        //savedData={savedData}
                        //selectedSentimentType={selectedSentimentType}
                        //comments={comments}
                        //onComment={(e)=>this.onComment(e)}
                        mainStyle={mainStyle}
                        closeModal={this.closeModal}
                       // onsaveFeedback={this.onsaveFeedback}
                    />
                </SendNewsLetterModal>
                    <div>
                            <label htmlFor="file">Upload Newsletter</label>
                 {uploading ? (
                   <div className="spinner-border" role="status">
                             <span className="sr-only">Loading...</span>
                   </div>
                 ) : (
                   <span className="alert-success" role="alert">
                     {uploadmsg}
                   </span>
                 )}
                            <br />
                 <input
                   type="file"
                   id="file"
                   accept="pdf"
                   onChange={this.FilehandleChange}
                 />
              
                  <div className={style.smallCaseSelect}>
                  <Select
                   value={fileSmallCase}
                   placeholder={<div>Select Small Case</div>}
                   className={style.selectCity}
                   onChange={this.handleFileSmallCase}
                   options={smallCaseOptions}
                   styles={customStyles}
                 />
                  </div>
               
                            
                           
                 <button onClick={() => this.OnFileSubmit(file)} disabled={file===null || fileSmallCase==="" } className={style.submitButton}>Submit</button>
                
                 <div className={style.emailSend}>
                 <Select
                   value={selectedSmallCase}
                   placeholder={<div>Select Small Case</div>}
                   className={style.selectCity}
                   onChange={this.handleSmallCaseChange}
                   options={smallCaseOptions}
                   styles={customStyles}
                 />
                    <SendEmail 
                           handleChange={this.handleChange}
                           cityData={cityData}
                            handleTextChange={this.handleTextChange}
                            onSave={this.onSave}
                            condition={condition}
                            fromEmail={fromEmail}
                            fromName={fromName}
                        />
                    </div>
               </div>
                         
               <div>
                 <Tabs className={style.tabs} selected={0}>
                        <NewsLetterPanel title={EditorName}>
                                <SendEmailEditorContent
                                    toggleList={toggleList}
                                    onToggle={this.onToggle}
                                    onChangeRawHtMl={this.onChangeRawHtMl}
                                    emailText={emailText}
                                    onChange={this.onChange}
                                    buttonHtml={buttonHtml}
                                    rawHtml={rawHtml}
                                />
                        </NewsLetterPanel> 
                        <NewsLetterPanel 
                        title="Preview"> <iframe 
                        title="preview" srcDoc={footerRender}></iframe></NewsLetterPanel>
                        </Tabs>
                    </div>
                 </div>
                </div>
         );
    }
}

const mapStateToProps = (state: any) => ({
    // send_newsletter_res: state.appStore.send_newsletter_res,
});

const mapDispatchToProps = (dispatch: any) => ({
    // sendnewsletter: (value: any, successCallback: any, failureCallback: any) => dispatch(sendnewsletter(value, successCallback, failureCallback)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SendNewsLetter);

