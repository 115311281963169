import React, { Component } from 'react';
import style from './SendNewsLetter.module.scss'



class NewsLetterPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            
        }
    }

    render() {
        const { children } = this.props;
        return <div className={style.prevCont}>{children}</div>
    }
}

export default NewsLetterPanel;