import React, { Component } from 'react';
import Select from 'react-select';
import style from  './SendNewsLetter.module.scss';
import './SendEmail.css'


const customStyles = {
    container: (base, state) => ({
        ...base,
        width: `80%`
      }),
      valueContainer: (base, state) => ({
        ...base,
      }),
      multiValue:(base, state) => ({
        ...base,
        backgroundColor: "#f4f7f8"
      }),
      indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: "#fff"
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: "#000"
      }),
      // option: (base, state) => ({
      //   ...base,
      //   color: "#f4f7f8 !important",
      //   backgroundColor: '#e8eeef !imporatnt'
      // }),
    menu: styles => ({ ...styles, backgroundColor: '#e8eeef' }),
    control: styles => ({ ...styles, backgroundColor: '#e8eeef' }),
     option: styles => ({ ...styles, color: '#f4f7f8 !imporatnt' }),
  };



class SendEmail extends Component {
    constructor(props){
        super(props);
        this.state = {
           
        }
    }

     
    render() {
        const { selectedOption,handleChange, colourStyles,handleTextChange, onSave,
            condition, fromEmail, fromName,cityData } = this.props;
        return (
            <div >
            <Select
                  value={selectedOption}
                  placeholder={<div>Select City</div>}
                  className={style.selectCity}
                  onChange={handleChange}
                  options={cityData}
                  styles={customStyles}
              />
            
            <input className={style.textField} type="text" id="subject" name="subject"  placeholder="Subject" onChange={(e) => handleTextChange(e,'subject')}/>
            <input className={style.textField} type="text" id="from-email" name="from-email" value={fromEmail} placeholder="From (email)" onChange={(e) => handleTextChange(e,'from-email')}/>
            <input className={style.textField} type="text" id="from-name" name="from-name" value={fromName} placeholder="From (name)" onChange={(e) => handleTextChange(e,'from-name')}/>   
            <button className={`${style.btn} ${style.save} `} disabled={!condition} onClick={onSave}>Send</button>
            </div>
            )
        }
}

export default SendEmail;