import React, { Component } from 'react';
import style from './SendEmail.module.scss';
import SendEmailEditor from './SendEmailEditor'
import EmailInfo from './EmailInfo';
import { connect } from 'react-redux';
import SendEmailModal from './SendEmailModal'
import SendEmailModalContent from './SendEmailModalContent'
import { modalStyle, mainStyle, colourStyles } from "../city/utils/style";
import { footerHtml, buttonHtml, getHtmlElement, blogFooter } from "../city/utils/footer";
import Smallcase from '../Smallcase/Smallcase'
import Tabs from '../city/Tabs';
import NewsLetterPanel from '../Smallcase/SendNewsLetter/NewsLetterPanel';
import { decrypt, encrypt } from "../../utils/encrypt";
import { sendTradeEmail } from '../../Actions/app.actions'

type Props = {
    sendTradeEmail: (b64: any,subject: string,to: string,from: any,body: string,filename: string, successCallback: any, failureCallback: any) => any;
    // tradeEmailresponse: string,
    loading:boolean
    tradeEmailresponse:any,
    failedtradeEmailresponse:string,
}

type State = {
    isModalOpen: boolean,
    responseData: string,
    error: boolean,
    rawHtml: string,
    toggleList: boolean,
    emailText: string,
    // fromName: string,
    subject: string,
    toEmail: string,
    selectedType: string,
    testHtml: string,
    // footerRender: string,
    uploading: boolean,
    uploadmsg: string,
    file: any,
    attachedFile: any,
    multipleUserSelected: any
    filename: string,
    loading: boolean,
    emailSuccessUsers:any,
    emailFailedUsers:any
    count: number,

}


class SendEmail extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isModalOpen: false,
            responseData: '',
            error: false,
            rawHtml: '',
            toggleList: false,
            emailText: '',
            // fromName:'',
            subject: '',
            toEmail: 'investor.relations@lotusdew.in',
            selectedType: 'email',
            testHtml: footerHtml,
            // footerRender: '',
            uploading: false,
            uploadmsg: '',
            file: null,
            attachedFile:null,
            multipleUserSelected:[],
            filename:'',
            loading: false,
            emailSuccessUsers:[],
            emailFailedUsers:[],
            count:0,
        }
    }

    componentDidMount() {

        this.setState({

        });

        this.FilehandleChange = this.FilehandleChange.bind(this);
        this.OnFileSubmit = this.OnFileSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.saveTextEditorContent = this.saveTextEditorContent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onChangeRawHtMl = this.onChangeRawHtMl.bind(this);
        this.onSave = this.onSave.bind(this);
        this.FilehandleChange = this.FilehandleChange.bind(this);
        this.OnFileSubmit = this.OnFileSubmit.bind(this);
        this.convertBase64 = this.convertBase64.bind(this);
        this.MultiSelectHandleChange = this.MultiSelectHandleChange.bind(this);
        this.sendTradeEmailSucess = this.sendTradeEmailSucess.bind(this);
        this.sendTradeEmailFailed = this.sendTradeEmailFailed.bind(this);
    }


    closeModal = () => {
        const { responseData, error } = this.state;
        this.setState({
            isModalOpen: false,
            responseData: '',
            //savedData:'',
        });
        if (error === true) {
            // console.log("failed to send email.");
        }
    }

    openModal = () => {
        this.setState({
            isModalOpen: true
        });
    }

    handleTextChange = (e: any, type: any) => {
        // console.log(e.target, " : ", e.target.value)
        switch (type) {
            case "subject":
                this.setState({
                    subject: e.target.value
                });
                break;
            // case "from-name":
            //     this.setState({
            //         fromName: e.target.value
            //     });
            //     break;
            case "to-email":
                this.setState({
                    toEmail: e.target.value
                });
                break;
            default:
                // console.log("unknown category");
                break;
        };

    }


    saveTextEditorContent = (content: any) => {
        // console.log("content:", content)
        var len = content.length;


        const totalData = {
            html_string: content,
        }

        localStorage.setItem('emailDraft', JSON.stringify(totalData));
    }


    onChange = (content: any) => {

        // console.log(content, "content")
        const { selectedType } = this.state;

        if (selectedType === 'email') {

            this.saveTextEditorContent(content);
            this.setState({
                emailText: content,
                testHtml: getHtmlElement(content),
                // footerRender: getHtmlElement(content),

            });
        }
        else {
            this.setState({
                emailText: content,
                // footerRender: content,

            });
        }

    }
    onToggle = () => {
        const { toggleList } = this.state;
        this.setState({ toggleList: !toggleList });
    }

    onChangeRawHtMl = (e: any) => {
        // console.log("this onChangeRawHtMl", e.target.value)
        this.setState({ rawHtml: e.target.value });
    }

    onSave = () => {
        const { subject, toggleList, rawHtml, testHtml,
            toEmail, emailText,attachedFile,multipleUserSelected, filename,count } = this.state;
        const { sendTradeEmail }  = this.props;
        let finalHtml = testHtml;
        // console.log(emailText, "Email Text")
        // if(testHtml === '' && emailText !== ''){
        // finalHtml = getHtmlElement(emailText)
        finalHtml = emailText
        // }
        const htmlString = toggleList ? rawHtml : finalHtml;
        // console.log(htmlString, "finalHtml")
        if(attachedFile === null && count === 0){
            this.setState({
                            responseData: "You are sending mail without any attachment!",
                        });
            this.openModal();
            this.setState({
                count: count + 1
            })
        }
        sendTradeEmail(attachedFile, subject, toEmail, multipleUserSelected, htmlString, filename, this.sendTradeEmailSucess, this.sendTradeEmailFailed)

    }

    sendTradeEmailSucess() {
        const { tradeEmailresponse } = this.props;
        // console.log("tradeEmailresponse",tradeEmailresponse)
        var item = Array.isArray(tradeEmailresponse) ? tradeEmailresponse.map(x => {
            if(x.success){
                return x.email
            }
        }) : []
        this.setState({
            emailSuccessUsers: item
        })
        var item = Array.isArray(tradeEmailresponse) ? tradeEmailresponse.map(x =>  
            {if(!x.success){
            return x.email
            }
        }) : []
        this.setState({
            emailFailedUsers: item
        })
        
       
        this.setState({
            loading: false,
            error: false,
            // responseData: "Success",
        })
        this.openModal();

    }
    sendTradeEmailFailed() {

        const { failedtradeEmailresponse } = this.props;
        this.setState({
            loading: false,
            error: true,
            responseData: failedtradeEmailresponse,
        })
        this.openModal();
    }


    FilehandleChange(e: any) {

        var fileInput: any =
            document.getElementById('file');

        var filePath = fileInput.value;

        // Allowing file type
        var allowedExtensions =
            /(\.csv|\.xlsx|\.xls)$/i;

        if (!allowedExtensions.exec(filePath)) {
            alert('Invalid file type, Please upload excel fle only!');
            fileInput.value = '';
            return false;
        }
        else {

            // console.log(e.target.files[0])
            const files = e.target.files;
            if (files && files[0]) this.setState({
                file: files[0],

            });

            setTimeout(
                () => this.OnFileSubmit(this.state.file), 
                2000
              );

        }

        

    };


    async OnFileSubmit(file: any) {
        // console.log("file in onFileSubmit:",file.name)
        this.setState({
            filename: file.name
        })
        if (file !== null) {

            const base64 = await this.convertBase64(file);
            this.setState({
                attachedFile: base64
            })
            // console.log("base64", base64)

            // let options = {
            //     method: 'POST',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({ b64: base64 })
            // }

            // console.log("options",options)


            // this.setState({ uploading: true })
            // fetch("https://chat-crm.lotusdew.in/", options)
            //     .then(resp => resp.json())
            //     .then(result => {

            //         // alert(result.message)
            //         this.setState({
            //             uploading: false,
            //             uploadmsg: result.message
            //         })

            // })
        }

        else {
            alert("Please select a File to upload!");
        }

    }

    convertBase64(file: any) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    MultiSelectHandleChange(event:any) {
        // console.log("event",event)
           var item = Array.isArray(event) ? event.map(x => x.value) : []
           this.setState({ multipleUserSelected: item })
       }

    render() {
        const {responseData, rawHtml, toggleList, emailText, toEmail, subject, uploading, uploadmsg, file,emailFailedUsers, emailSuccessUsers } = this.state;
        const condition = (subject !== "" && toEmail !== "" && (rawHtml !== '' || emailText !== '')) ? true : false
        const EditorName = 'email';
        // console.log("condition:", condition)
        return (
            <div>
                <Smallcase />
                <hr />
                <div className={style.sendEmailContainer}>
                    <SendEmailModal
                        isModalOpen={this.state.isModalOpen}
                        closeModal={this.closeModal}
                        style={modalStyle}
                    >
                        <SendEmailModalContent
                            responseData={responseData}
                            emailSuccessUsers = {emailSuccessUsers}
                            emailFailedUsers = {emailFailedUsers}
                            mainStyle={mainStyle}
                            closeModal={this.closeModal}
                        />
                    </SendEmailModal>
                    <div className={style.infoContainer}>
                        <label htmlFor="file">Attach File</label>
                        {uploading ? (
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : (
                            <span className="alert-success" role="alert">
                                {uploadmsg}
                            </span>
                        )}
                        <br />
                        <input
                            type="file"
                            id="file"
                            accept="pdf"
                            onChange={this.FilehandleChange}
                        />
                        <br/>
                        {/* <button onClick={() => this.OnFileSubmit(file)} disabled={file === null} className={style.submitButton}>Submit</button> */}
                    <EmailInfo
                        handleTextChange={this.handleTextChange}
                        onSave={this.onSave}
                        condition={condition}
                        toEmail={toEmail}
                        MultiSelectHandleChange={this.MultiSelectHandleChange}
                        //  fromName={fromName}
                        subject={subject}
                    />
                </div>
                {/* <Tabs className={style.tabs} selected={0}>
                    <NewsLetterPanel title={EditorName}> */}
                    <SendEmailEditor
                        toggleList={toggleList}
                        onToggle={this.onToggle}
                        onChangeRawHtMl={this.onChangeRawHtMl}
                        emailText={emailText}
                        onChange={this.onChange}
                        buttonHtml={buttonHtml}
                        rawHtml={rawHtml}
                    />
                    {/* </NewsLetterPanel>
                    <NewsLetterPanel 
                        title="Preview"> <iframe 
                        title="preview" srcDoc={footerRender}></iframe></NewsLetterPanel>
                        </Tabs> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    fromUsers:state.appStore.fromUsers,
    // tradeEmailresponse: state.appStore.tradeEmailresponse,
    loading:  state.appStore.loading,
    tradeEmailresponse:state.appStore.tradeEmailresponse,
    failedtradeEmailresponse: state.appStore.failedtradeEmailresponse
});

const mapDispatchToProps = (dispatch: any) => ({
    sendTradeEmail: (b64: any,subject: string,to: string,from: any,body: string,filename: string, successCallback: any, failureCallback: any) => dispatch(sendTradeEmail(b64,subject,to,from,body,filename, successCallback, failureCallback)),
   
});
export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);

