import React, { Component } from 'react';
import Select from 'react-select';

import style from '../city/City.module.scss';
// import Modal from "../Modal";

const colourStyles = {
    container: (base, state) => ({
        ...base,
      }),
      valueContainer: (base, state) => ({
        ...base,
      }),
      multiValue:(base, state) => ({
        ...base,
        backgroundColor: "#f4f7f8"
      }),
      indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: "#fff"
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        color: "#000"
      }),
      // option: (base, state) => ({
      //   ...base,
      //   color: "#f4f7f8 !important",
      //   backgroundColor: '#e8eeef !imporatnt'
      // }),
    menu: styles => ({ ...styles, backgroundColor: '#e8eeef' }),
    control: styles => ({ ...styles, backgroundColor: '#e8eeef' }),
     option: styles => ({ ...styles, color: '#f4f7f8 !imporatnt' }),
  };

class Email extends Component {
    constructor(props){
        super(props);
        this.state = {
    
        }
    }
    

    render() {
        const {  selectedOption,  handleChange, cityData, colourStyles, selectedCategoryOption, handleCategoryChange, categoryList,
            selectedSubcription, subsEnabled, handleSubscriptionChange, SubscriptionList, handleTextChange, onSave,
            condition, fromEmail, fromName,handleFileSmallCase,fileSmallCase } = this.props;
          // console.log(fileSmallCase,"fileSmallCase")
            const smallCaseOptions = [
              { label: "Listed Venture Capital", value: "Listed Venture Capital" },
              { label: "Nanocap Champs", value: "Nanocap Champs" },
              {
                label: "ESG Core Portfolio",
                value: "ESG Core Portfolio",
              },
              {
                label: "None",
                value: null,
              }
            ];
        return (
            <>
                <Select
                  value={selectedOption}
                  placeholder={<div>Select City</div>}
                  isMulti
                  className={style.selectCity}
                  onChange={handleChange}
                  options={cityData}
                  styles={colourStyles}
              />
              <Select
                  value={selectedCategoryOption}
                  placeholder={<div>Select Category</div>}
                  isMulti
                  className={style.selectCategory}
                  onChange={handleCategoryChange}
                  options={categoryList}
                  styles={colourStyles}
              />
               <Select
                   value={fileSmallCase}
                   placeholder={<div>Select Small Case</div>}
                   className={style.selectCategory}
                   onChange={(e)=>handleFileSmallCase(e)}
                   options={smallCaseOptions}
                   styles={colourStyles}
                 />
              <Select
                  value={selectedSubcription}
                  placeholder={<div>Select Subscription Status</div>}
                  isMulti
                  isDisabled={!subsEnabled}
                  className={style.selectSubscription}
                  onChange={handleSubscriptionChange}
                  options={SubscriptionList}
                  styles={colourStyles}
              />
              <div className={style.inputFiledCont}>
                  <input className={style.textField} type="text" id="subject" name="subject"  placeholder="Subject" onChange={(e) => handleTextChange(e,'subject')}/>
                  <input  className={style.textField} type="text" id="from-email" name="from-email" value={fromEmail} placeholder="From (email)" onChange={(e) => handleTextChange(e,'from-email')}/>
                  <input  className={style.textField} type="text" id="from-name" name="from-name" value={fromName} placeholder="From (name)" onChange={(e) => handleTextChange(e,'from-name')}/>   
                <button className={`${style.btn} ${style.save}`} disabled={condition} onClick={onSave}>Send</button>
            </div>
            </>
        )
    }
}

export default Email;